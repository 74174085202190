@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("#{$icon-path}/MaterialIcons-Regular.eot"); /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url("#{$icon-path}/MaterialIcons-Regular.woff2") format("woff2"),
    url("#{$icon-path}/MaterialIcons-Regular.woff") format("woff"),
    url("#{$icon-path}/MaterialIcons-Regular.ttf") format("truetype");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  //font-size: 24px;  /* Google preferred icon size */
  font-size: 16px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

.material-icons.orange {
  color: rgb(235, 116, 9);
}
.material-icons.green {
  color: rgb(50, 205, 50);
}
.material-icons.red {
  color: rgb(188, 54, 24);
}
.material-icons.grey {
  color: #666;
}
.material-icons.lightgrey {
  color: rgb(153, 153, 153);
}

.material-icons.black {
  color: $black;
}

.material-icons.large {
  font-size: 20px;
}

.material-icons:before {
  position: relative;
  top: 3px;
}

.help-center:before {
  position: relative;
  top: 2px;
}

.check-circle:before {
  content: "\E86C";
}

.cancel-circle:before {
  content: "\E5C9";
}

.material-icons.error:before {
  content: "\E000";
}

.material-icons.warning:before {
  content: "\E002";
}

.material-icons.delete:before {
  content: "\E872";
}

.material-icons.play-circle:before {
  content: "\E038";
}

.material-icons.edit:before {
  content: "\E254";
}

.material-icons.menu:before {
  content: "\E5D2";
}

.material-icons.check_box:before {
  content: "\E834";
}

.material-icons.add:before {
  content: "\E145";
}

.material-icons.done:before {
  content: "\E876";
}

.material-icons.insert_drive_file:before {
  content: "\E24D";
}

.material-icons.book:before {
  content: "\E865";
}

.material-icons.expand_more:before {
  content: "\E5CF";
}

.material-icons.chevron_right:before {
  content: "\E5CC";
}

.material-icons.help:before {
  content: "\E887";
}

.material-icons.unfold_more:before {
  content: "\E5D7";
}