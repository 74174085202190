/* -------------------------------------------------------------------------------------------------
 * SAP - Flags for the Country/Language selection
 * ---------------------------------------------------------------------------------------------- */

 .country-language-select {
 	.flag {
		border-radius: 100%;
		display: block;
		float: left;
		padding-right: 1em;
		img {
			max-width: 25px;
		}
	}
	.content {
 		float: left;
 		width: calc(100%  - 45px);
 		h4 {
			color: $white;
			font-size: $font-small;
			margin-bottom: 2px;
		}
		a {
			font-size: $font-small;
		}
 	}
	
	
 }// /.country-language-select