/* -------------------------------------------------------------------------------------------------
 * SAP - Sitewide layout - Sitewide components
 * ---------------------------------------------------------------------------------------------- */

/* Site nav
-------------------------------------------------- */


.banner {
	background-color: $black;
	color: $white;
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 22;
	// md up (> 768px) ---------------------------------------------------------------------------------
	@include media-breakpoint-up(md) {
		position: relative;
	} // /@include media-breakpoint-up(md)

	.navbar {
		padding: 12px 0;

		#logo {
			float: left;
			margin: 0;
			min-height: 39px;
			line-height: 20px;
			width: 100%;
			a {
				display: block;
				background-image: url('#{$image-path}/sap_logo.svg');
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center;
				color: $white;
				@include font-bentonsans-medium;
				font-size: 20px;
				min-height: 45px;
				max-width: 90px;
				text-transform: uppercase;

				// md up (> 768px) ---------------------------------------------------------------------------------
				@include media-breakpoint-up(md) {
					margin-bottom: 0em;
				} // /@include media-breakpoint-up(md)

				&:hover {
					text-decoration: none;
				}// &:hover
				span {
					white-space: nowrap;
					transition: opacity $anim-dur ease-in-out;
				}
			}// /a
		}// /#logo
		.lhub-logo {
			@include font-bentonsans-medium;
			font-size: $font-medium;
			padding: 0;
			// lg up (> 992px) ---------------------------------------------------------------------------------
			@include media-breakpoint-up(lg) {
				font-size: $font-large;
			} // /@include media-breakpoint-up(lg)
			&--grey {
				color: $greyLight;
			}
			&--orange {
				color: $sap-gold;
			}
		}// /.lhub-logo
		.menu {
			line-height: 45px;

			.open {
			  i {
					color: black;
					transition: color $anim-dur ease-in-out;
			  }
			}

			i {
				font-size: $font-medium;
				position: relative;
				top: 2px;
				&.logged-in {
					
					&.icon-bubble {
						font-size: $font-20px;
					}
				}
			}// /i
			&-cms {
				i {
					font-size: $font-smaller;
				}// /i
			}
			&-nav {
				display: none;
				line-height: 1.5;
				box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
				max-width: 290px;
				position: absolute;
				right: 1em;
				bottom: -.75em;
				transform: translateY(100%);
				transition: opacity $anim-dur ease-in-out;
				z-index: 22;
				li {
					@include font-bentonsans-medium;
					font-size: $font-regular;
					margin-bottom: 1em;
					&:last-child {
						margin-bottom: 0;
					}
				}
			}// /&-nav
			&.is-active {
				.menu-nav {
					display: block;
				}
			}
		}

	}// /.navbar

}// /.banner

.subnav {
	background-color: $greyLight;
	
	// md up (> 768px) ---------------------------------------------------------------------------------
	@include media-breakpoint-up(md) {
		position: relative;
		top: 0;
		left: 0;
	} // /@include media-breakpoint-up(md)

	.navbar {
		padding: 12px 0;
		.menu {
			line-height: 45px;
			position: relative;
			width: auto;
			float: left;
			&-btn {
				display: block;
				padding-right: 3rem;
				color: black;
				font-weight: bold;
			}

			i {
				font-size: $font-medium;
				position: absolute;
				line-height: inherit;
				right: 1rem;
				top: 0px;
			}// /i
			&-nav {
				display: none;
				line-height: 1.5;
				box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
				max-width: 290px;
				position: absolute;
				right: 1em;
				bottom: -.75em;
				transform: translateY(100%);
				transition: opacity $anim-dur ease-in-out;
				z-index: 20;
				min-width: auto;
				// md up (> 768px) ---------------------------------------------------------------------------------
				@include media-breakpoint-up(md) {
					min-width: 220px;
				} // /@include media-breakpoint-up(md)
				li {
					@include font-bentonsans-medium;
					font-size: $font-regular;
					margin-bottom: 1em;
					&:last-child {
						margin-bottom: 0;
					}
				}
			}// /&-nav
			&.is-active {
				.menu-nav {
					display: block;
				}
				i {
						transform: rotate(180deg);
					}
			}
		}// /.nav-bar
	}// /.menu
}// /.subnav
