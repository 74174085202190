/* -------------------------------------------------------------------------------------------------
 * SAP - Third-party overrides
 * Overides styles for third-party plugins
 * ---------------------------------------------------------------------------------------------- */

// BOOSTRAP Modal override
// ---------------------------------------------------------------------------------
.modal-content {
	border-radius: 0;
	.modal-header,
	.modal-body,
	.modal-footer {
		padding: 1.25em;
		input[type="radio"].radio-plain {
			margin-top: 2px;
			opacity: 1;
		}
	}
	.border-column {
		position: relative;
		&.column-left {
			&:after {
				right: 0em;
			}// /&:after
		}
		&.column-right {
			&:after {
				left: 0em;
			}// /&:after
		}
		&:after {
			background-color: $greyLighter;
			content: '';
			display: none;
			height: 100%;
			width: 1px;
			position: absolute;
			top: 0%;
			// md up (> 768px) ---------------------------------------------------------------------------------
			@include media-breakpoint-up(md) {
				display: block;
			} // /@include media-breakpoint-up(md)
		}// /&:after
	}// /.border-column
}


// SELECT2 override
// ---------------------------------------------------------------------------------
.select2-container .select2-selection--single {
	height: 38px;
	&.has-danger {
		border-color: $sap-red-medium;
	}// /&.has-danger
}
.has-danger .select2-container--default .select2-selection--single {
	border-color: $sap-red-medium;
}
.select2-container--default {
	width: 100% !important;
	.select2-selection--single {
		border: 1px solid #ccc;
		.select2-selection__rendered {
			line-height: 38px;
		}
		.select2-selection__arrow {
			height: 36px;
		}
	}
}// /.select2-container--default

.select2-selection {
	&.select2-selection--multiple {
		border-color: $greyLighter;

		li:before {
			content: '';
		}
      .select2-selection__choice, .select2-search.select2-search--inline {
        &:before {
          content: "";
        }
      }

	}// /&.select2-selection--multiple
}// /.select2-selection


.select2-container--default.select2-container--focus {
	.select2-selection--multiple {
		border-color: $greyLighter;
	}
}

// DATE PICKER
// ---------------------------------------------------------------------------------

 /*! jQuery UI - v1.10.4 - 2014-11-30
* http://jqueryui.com
* Includes: jquery.ui.core.css, jquery.ui.datepicker.css, jquery.ui.theme.css
* To view and modify this theme, visit http://jqueryui.com/themeroller/?ffDefault=Trebuchet%20MS%2CTahoma%2CVerdana%2CArial%2Csans-serif&fwDefault=bold&fsDefault=1.1em&cornerRadius=4px&bgColorHeader=f6a828&bgTextureHeader=gloss_wave&bgImgOpacityHeader=35&borderColorHeader=e78f08&fcHeader=ffffff&iconColorHeader=ffffff&bgColorContent=eeeeee&bgTextureContent=highlight_soft&bgImgOpacityContent=100&borderColorContent=dddddd&fcContent=333333&iconColorContent=222222&bgColorDefault=f6f6f6&bgTextureDefault=glass&bgImgOpacityDefault=100&borderColorDefault=cccccc&fcDefault=1c94c4&iconColorDefault=ef8c08&bgColorHover=fdf5ce&bgTextureHover=glass&bgImgOpacityHover=100&borderColorHover=fbcb09&fcHover=c77405&iconColorHover=ef8c08&bgColorActive=ffffff&bgTextureActive=glass&bgImgOpacityActive=65&borderColorActive=fbd850&fcActive=eb8f00&iconColorActive=ef8c08&bgColorHighlight=ffe45c&bgTextureHighlight=highlight_soft&bgImgOpacityHighlight=75&borderColorHighlight=fed22f&fcHighlight=363636&iconColorHighlight=228ef1&bgColorError=b81900&bgTextureError=diagonals_thick&bgImgOpacityError=18&borderColorError=cd0a0a&fcError=ffffff&iconColorError=ffd27a&bgColorOverlay=666666&bgTextureOverlay=diagonals_thick&bgImgOpacityOverlay=20&opacityOverlay=50&bgColorShadow=000000&bgTextureShadow=flat&bgImgOpacityShadow=10&opacityShadow=20&thicknessShadow=5px&offsetTopShadow=-5px&offsetLeftShadow=-5px&cornerRadiusShadow=5px
* Copyright 2014 jQuery Foundation and other contributors; Licensed MIT */


/* Layout helpers
----------------------------------*/
.ui-helper-hidden {
	display: none;
}
.ui-helper-hidden-accessible {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
.ui-helper-reset {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	line-height: 1.3;
	text-decoration: none;
	font-size: 100%;
	list-style: none;
}
.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
	content: "";
	display: table;
	border-collapse: collapse;
}
.ui-helper-clearfix:after {
	clear: both;
}
.ui-helper-clearfix {
	min-height: 0; /* support: IE7 */
}
.ui-helper-zfix {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
	opacity: 0;
	filter:Alpha(Opacity=0);
}

.ui-front {
	z-index: 100;
}


/* Interaction Cues
----------------------------------*/
.ui-state-disabled {
	cursor: default !important;
}

/* Misc visuals
----------------------------------*/

/* Overlays */
.ui-widget-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.ui-datepicker {
	background-color: #eef1f3;
	box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.75);
	display: none;
	padding: 20px;
	z-index: 1050 !important;
	&-header {
		position: relative;
	}
	.ui-datepicker-prev,
	.ui-datepicker-next {
		color: $grey;
		cursor: pointer;
		padding: 0;
		position: absolute;
		line-height: 18px;
		text-align: center;
		top: 0;
		&::before {
			font-family: 'icomoon' !important;
			speak: none;
			font-style: normal;
			font-weight: bold;
			font-variant: normal;
			text-transform: none;
			text-align: center;
		}
		&-hover {
			color: darken($grey, 15%);
		}
		span {
			display: none;
		}
	}
	.ui-datepicker-prev {
		left: 0;
		&::before {
			content: '\e931';
		}
	}
	.ui-datepicker-next {
		right: 0;
		&::before {
			content: '\e930';
		}
	}
	.ui-datepicker-title {
		color: $grey;
		@include font-bentonsans-bold;
		font-size: 1rem;
		line-height: 1;
		margin-bottom: 30px; //30
		text-align: center;
		select {
			font-size: 1em;
			margin: 1px 0;
		}
	}

	select
	table {
		width: 100%;
		@include font-bentonsans-medium;
		font-size: 12px;
		border-collapse: collapse;
		background: none;
		margin: 0;
		border: 0;
	}
	table {
		td,
		th{
			&:first-child {
				padding-left: 0.3rem;
			}
		}
	}
	th {
		padding: .7em .3em;
		text-align: center;
		border: 0;
		background: none;
		@include font-bentonsans-bold;
		text-transform: uppercase;
	}
	td {
		border: 0;
		padding: 0;
		span,
		a {
			display: block;
			font-size: 12px;
			padding: .2em;
			text-align: center;
			text-decoration: none;
			text-shadow: none;
		}
	}

	.ui-datepicker-buttonpane {
		background-image: none;
		margin: .7em 0 0 0;
		padding: 0 .2em;
		border-left: 0;
		border-right: 0;
		border-bottom: 0;
		button {
			float: right;
			margin: .5em .2em .4em;
			cursor: pointer;
			padding: .2em .6em .3em .6em;
			width: auto;
			overflow: visible;
			&.ui-datepicker-current {
				float: left;
			}
		}
	}
}// /.ui-datepicker

.ui-datepicker-inline {
	background-color: #eef1f3;
	border: 0;
	box-shadow: none;
}




/* with multiple calendars */
.ui-datepicker.ui-datepicker-multi {
	width: auto;
}
.ui-datepicker-multi {
	.ui-datepicker-group {
		float: left;
		table {
			width: 95%;
			margin: 0 auto .4em;
		}
	}
}
.ui-datepicker-multi-2 .ui-datepicker-group {
	width: 50%;
}
.ui-datepicker-multi-3 .ui-datepicker-group {
	width: 33.3%;
}
.ui-datepicker-multi-4 .ui-datepicker-group {
	width: 25%;
}
.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
	border-left-width: 0;
}
.ui-datepicker-multi .ui-datepicker-buttonpane {
	clear: left;
}
.ui-datepicker-row-break {
	clear: both;
	width: 100%;
	font-size: 0;
}

/* RTL support */
.ui-datepicker-rtl {
	direction: rtl;
}
.ui-datepicker-rtl .ui-datepicker-prev {
	right: 2px;
	left: auto;
}
.ui-datepicker-rtl .ui-datepicker-next {
	left: 2px;
	right: auto;
}
.ui-datepicker-rtl .ui-datepicker-prev:hover {
	right: 1px;
	left: auto;
}
.ui-datepicker-rtl .ui-datepicker-next:hover {
	left: 1px;
	right: auto;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane {
	clear: right;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane button {
	float: left;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current,
.ui-datepicker-rtl .ui-datepicker-group {
	float: right;
}
.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
	border-right-width: 0;
	border-left-width: 1px;
}

/* Component containers
----------------------------------*/
.ui-widget .ui-widget {
	font-size: 1em;
}
.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
	font-family: Trebuchet MS,Tahoma,Verdana,Arial,sans-serif;
	font-size: 1em;
}

.ui-widget-content a {
	color: #333333;
}
.ui-widget-header {
	font-size: 18px;
	font-weight: bold;
}
.ui-widget-header a {
	color: #ffffff;
}

/* Interaction states
----------------------------------*/
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
	color: $grey;
}
.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited {
	color: #1c94c4;
	text-decoration: none;
}
.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
	color: $greyDark;
	background: #d6d8da !important;
}
.ui-state-hover a,
.ui-state-hover a:hover,
.ui-state-hover a:link,
.ui-state-hover a:visited,
.ui-state-focus a,
.ui-state-focus a:hover,
.ui-state-focus a:link,
.ui-state-focus a:visited {
	color: $greyDark;
	background: #d6d8da !important;
	text-decoration: none;
}
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
	background-color: rgb(123,134,141);
	color: #fff;
}
.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
	color: $greyDark;
	text-decoration: none;
}

/* Interaction Cues
----------------------------------*/
.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
	background: lightsteelblue;
}
.ui-state-highlight a,
.ui-widget-content .ui-state-highlight a,
.ui-widget-header .ui-state-highlight a {
	color: $greyDark;
	background: lightsteelblue;
}
.ui-state-error,
.ui-widget-content .ui-state-error,
.ui-widget-header .ui-state-error {
	border: 1px solid #cd0a0a;
	background: #b81900 url("images/ui-bg_diagonals-thick_18_b81900_40x40.png") 50% 50% repeat;
	color: #ffffff;
}
.ui-state-error a,
.ui-widget-content .ui-state-error a,
.ui-widget-header .ui-state-error a {
	color: #ffffff;
}
.ui-state-error-text,
.ui-widget-content .ui-state-error-text,
.ui-widget-header .ui-state-error-text {
	color: #ffffff;
}
.ui-priority-primary,
.ui-widget-content .ui-priority-primary,
.ui-widget-header .ui-priority-primary {
	font-weight: bold;
}
.ui-priority-secondary,
.ui-widget-content .ui-priority-secondary,
.ui-widget-header .ui-priority-secondary {
	opacity: .7;
	filter:Alpha(Opacity=70);
	font-weight: normal;
}
.ui-state-disabled,
.ui-widget-content .ui-state-disabled,
.ui-widget-header .ui-state-disabled {
	opacity: .35;
	filter:Alpha(Opacity=35);
	background-image: none;
}
.ui-state-disabled .ui-icon {
	filter:Alpha(Opacity=35); /* For IE8 - See #6059 */
}

// Additional
.ui-datepicker-today {
	& > .ui-state-highlight,
	& > .ui-state-hover,
	& > .ui-state-active,
	& > .ui-state-default {
		background: none;
		background-color: rgb(255,255,255);
		border: 1px solid #515253;
		color: #515253;
		padding: .14em;
	}
}
.ui-datepicker-range {
	@include font-bentonsans-medium;
	font-size: 12px;
	font-weight: normal;
	margin-top: 20px;
	text-align: center;

	span {
		margin-right: 10px;
	}
}

/* Overlays */
.ui-widget-overlay {
	//background: #666666 url("images/ui-bg_diagonals-thick_20_666666_40x40.png") 50% 50% repeat;
	opacity: .5;
	filter: Alpha(Opacity=50);
}
.ui-widget-shadow {
	margin: -5px 0 0 -5px;
	padding: 5px;
	//background: #000000 url("images/ui-bg_flat_10_000000_40x100.png") 50% 50% repeat-x;
	opacity: .2;
	filter: Alpha(Opacity=20);
	border-radius: 5px;
}

#courseDate .action-bottom {
	//.clearfix();
	//margin-top: 20px;

	.sap-trn-btn {
		float: right;
	}
}

.date-range-selected {

	& > .ui-state-active,
	& > .ui-state-default {
		background: none;
		background-color: rgb(123,134,141);
		color: rgb(250,250,250);
	}
	&.ui-datepicker-week-end {
		& > .ui-state-active,
		& > .ui-state-default {
			color: #515253;
		}
	}
}
/* IDS Login
----------------------------------*/
.ids-overlay-container {
	max-height: 100% !important;
	overflow-y: scroll !important;
	// md up (> 768px) ---------------------------------------------------------------------------------
	@include media-breakpoint-up(md) {
		max-height: calc(100% - 56px) !important;

	} // /@include media-breakpoint-up(md)

}

// Slick-Carousel override
// ---------------------------------------------------------------------------------
.slick-slider {
	position: relative;
	width: 100%;
	overflow: hidden;
	visibility: hidden;
	opacity: 0;
	transition: opacity $anim-dur ease-in;
	max-height: 380px;
	&.slick-initialized {
		opacity: 1;
		visibility: visible;
	}
}
.slick-slide {
    float: left;
}
.slick-dots {
    // display: none;
    position: absolute;
    bottom: 1em !important;
    margin-bottom: 0 !important;
    padding: 0;
    width: auto;
		left: 50%;
		transform: translateX(-50%) !important;
    text-align: center;
    li {
        display: inline-block;
        margin-right: 10px;
		&:before {
			display: none;
		}
        &.slick-active {
            button {
                background-color: $sap-blue-medium !important;
                transition: background-color .25s ease-in-out;

            } // /button
        } // /&.slick-active

        button {
            background-color: transparent !important;
            border: 3px solid $white !important;
            border-radius: 10px;
            font-size: 0;
            height: 20px;
            width: 20px;

            &:before {
                display: none;
            } // /&:before
        } // /button
    } // /li
}
