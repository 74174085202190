/* -------------------------------------------------------------------------------------------------
 * SAP - Widget styles
 * ---------------------------------------------------------------------------------------------- */

#loading-image {
  height: 150px;
  position: relative;
}

.dropdown-toggle:after {
  display: none;
}

.dropdown {
  text-align: center;
  min-width: 40px;
  transition: all $anim-dur ease-in-out;
  // md up (> 768px) ---------------------------------------------------------------------------------
  @include media-breakpoint-up(md) {
    min-width: 50px;
  } // /@include media-breakpoint-up(md)

  &.open {
    background-color: white;
    color: black;
  }
}

.dropdown-menu {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px 0 rgba(0,0,0,.5);
  border-radius: 0px;
  margin: 0px;
  padding-bottom: 8px;
  border: none;
}

// Modal styles
.modal {
  &-header {
    h3 {
      font-size: 27px;
      font-weight: 400;

    }
  }
}

// 
.ui-state-default {
  a,
  a:link, 
  a:visited {
    color: inherit;
    .tile__actions & {
      color: inherit;
    }
  }
}