/* -------------------------------------------------------------------------------------------------
 * SAP - Sitewide layout - Sitewide components
 * ---------------------------------------------------------------------------------------------- */

body {
	overflow-x: hidden;
	//padding-top: 69px;
	transition: padding $anim-dur ease-in-out;

	// md up (> 768px) ---------------------------------------------------------------------------------
	@include media-breakpoint-up(md) {
		padding-top: 0;
	} // /@include media-breakpoint-up(md)


	a {
		color: $sap-blue-medium;
		&:hover {
			color: lighten($sap-blue-medium, 10);
			text-decoration: none;
		}
		&.link-light {
			color: $sap-link-light;
			&:hover {
				color: lighten($sap-link-light, 10);
				text-decoration: none;
			}
		}
	}// /a

	ul {
		padding-left: 2em;

		li {
			font-size: $font-smaller;
			margin-bottom: .5em;
			list-style-type: none;
			&:before {
				color: rgb(56, 56, 56);
				content: "•";
				float: left;
				font-size: $font-larger;
				line-height: $font-small;
				margin-left: -1.25em;
				vertical-align: middle;
			}
			ul {
				font-size: $font-large;
				margin-top: .5em;
			}

			&.course {
				font-size: $font-regular;
				&:before {
					display: none;
				}
			}
		}
		&.unstyled {
			padding-left: 0;
			li {
				&:before {
					display: none;
				}
			}
		}
    &.simple-list {

      li {
        font-size: $font-small;
        margin-bottom: .25em;
        &:before {
          padding-right: 1em;
          padding-left: 1em;
          font-size: $font-small;
        }
      }
    }
    &.docs-list {

      li {
        font-size: $font-small;
        margin-bottom: .25em;
        &:before {
          color: $sap-blue-medium;
          padding-top: 3px;
          padding-right: 1em;
          padding-left: 1em;
          font-size: $font-small;
        }
      }
    }
		&.inline-list {
			font-size: $font-small;
			margin: 0;
			padding: 0;
			li {
				color: $greyLighter;
				display: inline-block;
				font-size: $font-small;
				@include font-bentonsans-medium;
				&:before {
					display: none;
				}
			}
		}
		&.arrow-list {
			padding: 0;
			> li {
				font-size: 1em;
				padding-left: 2em;
				position: relative;

				&:before {
					background-color: $sap-blue-medium;
					border-radius: 100%;
					content: "\e91f";
					color: $white;
					display: block;
					@include icon-style;
					font-size: .8rem;
					height: 15px;
					line-height: 15px;
					width: 15px;
					margin: 0;
					padding: 0 1px;
					position: absolute;
					top: .25em;
					left: 0;
					transition: transform $anim-fast ease-in-out;
				}
				&:active {
					&:before {
						transform: rotate(90deg);
					}
				}
				a {
					color: inherit;
				}
			}
			&.arrow-list-blue {
				> li {
					cursor: pointer;
					@include font-bentonsans-bold;
					color: $sap-blue-medium;
					> a {

						color: $sap-blue-medium;
						&:hover {
							color: $sap-blue-hover;
						}
					}
				}
			}
		}// /&.arrow-list
	}
	* {
		outline: none;
		&:active,
		&:focus {
			outline: none;
		}
	}// /form

	hr {
		background-color: $greyLighter;
		height: 1px;
		margin-bottom: 2em;
		&.ruler {
			background-color: $sap-gold;
			height: 6px;
		}
	}
	img {
		width: 100%;
	}
	.required {
		&:after {
	    	content: ' * ';
		}
		&.no-asterix {
			&:after {
		    	content: '';
			}// /&:after
		}// /&.no-asterix
	}// /.required
}// /body

.grey-panel {
	background-color: $sap-snow-grey;
}


// Alert overrides
// ----------------------------------------------------------------------------------------------
.alert {
	border-radius: 0;
	&.alert-block-no-margin{
		margin-bottom: 0;
	}// /&.alert-block-no-margin
	&.alert-danger {
		p {
			color: #a94442;
		}
	}
	&.alert-warning {
		p {
			color: #8a6d3b;
		}
	}
	&.alert-success {
		p {
			color: #3c763d;
		}
	}
	.help-block {
		font-size: $font-regular;
	}
}

.language-alert-dismiss {
	right: .25rem;
	position: relative;
	// md up (> 768px) ---------------------------------------------------------------------------------
	@include media-breakpoint-up(md) {
		right: .75rem;
	} // /@include media-breakpoint-up(md)
}
// Breadcrumbs
// ----------------------------------------------------------------------------------------------

ul.breadcrumb {
	background-color: transparent;
	padding: 0;
	li {
		color: $sap-blue-medium;
		display: inline-block;
		@include font-bentonsans-medium;
		font-size: $font-regular;
		line-height: 1;
		margin-bottom: 1em;
		&:before {
			color: inherit;
			content: '|';
			@include font-bentonsans;
			font-size: $font-small;
			margin-left: 0rem;
			padding-right: .5rem;
    		padding-left: .5rem;
		}// /&:before
		&:first-child {
			a {
				display: block;
			}// /a
			&:before {
				content: '';
			}// /&:before
		}// /&:first-child
		a {
			display: inline-block;
			&:hover {
				color: $blackAlphaOpaque;
				text-decoration: none;
			}// /&hover
		}// /a
	}// /li.breadcrumb-item
}
.landing-menu > li {
    float: none;
}
// HELPLINK
// ----------------------------------------------------------------------------------------------

.helplink {
	background-color: #f1f4f5;
	@include font-bentonsans-bold;
	font-size: $font-larger;//24px;
	line-height: 1;
	padding: 35px;
	text-align: center;
	a {
		color: rgba(2, 136, 209, 0.8);
		margin-left: 20px;
		transition: all $anim-dur ease-in-out;
		&:hover,
		&:active,
		&:focus {
			text-decoration: none;
		}// /&:hover
		i {
			//margin-left: .5em;
			position: relative;
			bottom: -5px;
		}
	}// /a

}// /.helplink


// Modal
// Locale Selection popup
// ----------------------------------------------------------------------------------------------
.locale-selection-scroll {
	max-height: 300px;
	overflow-y: scroll;
}// /locale-selection-scroll

.locale-selection {
	margin: 0;
	padding: 0;
	li {
		display: block;
		float: left;
		font-size: .8em;
		min-height: 40px;
		line-height: 25px;
		width: 100%;
		&:before {
			display: none;
		}// /&:before


		img {
			display: block;
			float: left;
			margin-right: 5px;
			max-width: 25px;
		}

		// sm up (> 544px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(sm) {

		} // /@include media-breakpoint-up(sm)

		// md up (> 768px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(md) {
			width: 33%;
		} // /@include media-breakpoint-up(md)

	}// /li
	&-regions {
		h2 {
			font-size: 22px;
		}
	}
}// /.locale-selection

// Popup Spinner

#modalSpinner {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,50%);
}



// Accordions
//_______________________________________________________________________________________________

.accordion {
	border-bottom: 0 none;
	margin: 0;
}// /.accordion

.accordion-group {
	border-top: 1px solid $greyLighter;
	cursor: pointer;
	&:first-child {
		border-top: none;
	}// /&:first-child
}// /.accordion-group

.accordion-heading,
.accordion-body {
	margin: 0;
	padding: 0;
}// /.accordion-heading, .accordion-body


.accordion-heading {
	@include font-bentonsans-bold;
	padding: 1em 2em 1em 1em;
	position: relative;
	transition: all $anim-dur ease-in-out;
	&:after {
		//color: $grey;
		content: "\e91f";
		display: block;
		font-family: 'icomoon' !important;
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;

		position: absolute;
		top: 1.25em;
		right: 1em;
		transform: rotate(0deg);
		transition: transform $anim-dur ease-in-out;
	}
	.accordion-toggle {
		@include clearfix;
	}
	&.active {
		background-color: $sap-snow-grey;
		// color: $grey;
		&:after {
			transform: rotate(90deg);
		}
	}
}// /.accordion-heading
.accordion-body {
	display: none;
	&.in {
		display: block;
	}
	.tool-tip {
		i {
			color: $greyLight;
			margin-left: 5px;
		}// /i
	}// /.tool-tip
}// /.accordion-body
.accordion-inner {
	background-color: $sap-snow-grey;
	.accordion-inner-list {

		li {
			margin-bottom: 35px;

			&:last-child {
				margin-bottom: 5px;
			}// /&:last-child
		}// /li
	}// /.accordion-inner-list
}// /.accordion-inner

.toggle-all-collapse {
	.show-all {
		display: inline-block;
	}
	.hide-all {
		display: none;
	}
	&.expand-all {
		.show-all {
			display: none;
		}
		.hide-all {
			display: inline-block;
		}
	}
}
// Scrollable containers
//_______________________________________________________________________________________________

.scroll-container {
	overflow-y: scroll;
	max-height: 305px;
}// /.scroll-container



// Dropdown menus
//_______________________________________________________________________________________________

ul.dropdown-menu {
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
	list-style-type: none;
	min-width: 250px;
	padding: 0;
	li {
		border-bottom: 1px solid $greyLightest;
		color: $blackAlphaOpaque;
		display: block;
		list-style: none;
		line-height: 1;
		margin: 0;
		padding: 0;
		text-align: left;
		&:before {
			display: none;
		}
		&:last-child {
			margin: 0;
			border-bottom: none;
		}
		h3 {
			padding: .5em 1em;
			margin: 0;
		}
		a {
			color: $blackAlphaOpaque;
			display: block;
			padding: 1em;
			margin: 0;
		}
	}
}// /ul.dropdown-menu




// YouTube videos
//_______________________________________________________________________________________________

.embed-container {
	margin-bottom: 1em;
	iframe,
	object,
	embed {
		height: 100%;
		min-height: 520px;
		width: 100%;
	}// /iframe, embed, object
	&--lg {
		// md up (> 768px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(md) {
			iframe,
			object,
			embed {
				min-height: 380px;
			}// /iframe, embed, object
		} // /@include media-breakpoint-up(md)
	}

}// /.embed-container


// Panels
//_______________________________________________________________________________________________

.panel {
	margin-bottom: 2em;
	&.panel-hero {
		margin-bottom: 0;
		&.panel-inverse {
			color: $white;
			.course-definition-list {
				color: $white;
				dt {
					color: $white;
				}
			}
		}// /&.panel-course
	}// /&panel-hero
	&.panel-well {
		background-color: $whiteDarker;
		padding: 1em;
	}// /&.panel-well
	.content-toggle {
		padding-bottom: 1.5em;
		position: relative;
		&.open {
			.content-toggle-inner {
				height: auto;
			}
			&:after {
				transform: rotate(180deg);
			}
		}
		.content-toggle-inner {
			height: 200px;
			overflow: hidden;
			// md up (> 768px) ---------------------------------------------------------------------------------
			@include media-breakpoint-up(md) {
				height: auto;
			} // /@include media-breakpoint-up(md)
		}// /.content-toggle-inner

		&:after {
			color: $white;
			content: "\e921";
			display: block;
			font-family: 'icomoon' !important;
			font-size: 2em;
			speak: none;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			line-height: 1;

			/* Better Font Rendering =========== */
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;

			position: absolute;
			bottom: -10px;
			left: 50%;
			margin-left: -16px;
			transform: rotate(0deg);
			transition: transform $anim-dur ease-in-out;
		}// /&:after
		// md up (> 768px) ---------------------------------------------------------------------------------
			@include media-breakpoint-up(md) {
				&:after {
					display: none;
				}
			} // /@include media-breakpoint-up(md)
	}// /.content-toggle

}// /.panel

// Panel with background, orange top-border
.panel-secondary {
	background: $sap-snow-grey;
	border-top: $borderWidthThick solid $sap-gold;
}

// Panel with transparent background, orange top-border
.panel-transparent {
	background: $whiteAlphaOpaque;
	border-top: 6px solid $sap-gold;
}

// Panel with white background and box shadow
.panel-shadow {
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
	margin-bottom: 3px;
}

// Panel in marketing sidebar (pulled right, blue background)
.panel-marketing-aside {
	background: $sap-snow-grey;
}


// Custom-styled form elements
//_______________________________________________________________________________________________

select {
	max-width: 100%;
}

.terms-and-cons {
	font-size: $font-regular;
}

dl {
	margin: 0 0 1em;

	&.indented {
		margin-left: 1em;
	}
}

dt,
dd {
	line-height: 1.25;

	.splitline & {
		line-height: 1.25;
		margin: 0;
		width: 100%;
	}
}

dt {
  font-family: bentonsansmedium,sans-serif;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	clear: left;
	color: #000000;
	float: left;
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 0.5em;
	width: 155px;
	// sm up (> 544px) ---------------------------------------------------------------------------------
	@include media-breakpoint-up(sm) {
		width: 195px;
	} // /@include media-breakpoint-up(sm)
}

dd {
  font-family: bentonsanslight,sans-serif;
	margin: 0 0 0.5em 140px;
	font-size: 14px;
	// sm up (> 544px) ---------------------------------------------------------------------------------
	@include media-breakpoint-up(sm) {
		margin: 0 0 1em 200px;
	} // /@include media-breakpoint-up(sm)

	.splitline & {
		color: rgba(0,0,0,.8);
		font-family: bentonsanslight,sans-serif;
		margin: 0 0 0.5em 0;

		div {
			float: left;
			ul {
				li {
					font-size: $font-regular;
				}
			}
		}
	}
}


// Tables
//_______________________________________________________________________________________________

table {
	background: $white;
	border-collapse: collapse;
	border-spacing: 0;
	border: $borderWidthThin solid $greyLightest;
	margin-bottom: $baseLineHeight;
	width: 100%;
	&.table {
		border-right: $borderWidthThin solid $greyLightest;
		border-left: $borderWidthThin solid $greyLightest;
		thead {
			th {
				vertical-align: top;
				border-top: 0;
				border-bottom: 0;
			}// /th
		}// /thead
	}// /&.table
	&.up-table {
		border: none;
		thead {
			tr {
				border-bottom: 1px solid $whiteDarker;
			}
			th {
				background: $sap-snow-white;
				vertical-align: middle;
				border-top: 0;
				border-bottom: 0;
				border-left: 0;
				color: $greyDarker;
				font-weight: normal;
				height: 60px;
			}// /th
		}// /thead
		tbody {
			background-color: $white;
			tr {
				border-bottom: 1px solid $whiteDarker;
			}
			td {
				background: $white !important;
				border: none;
				font-size: rem(14px);
				&:first-child {
					min-width: 150px;
					// md up (> 768px) ---------------------------------------------------------------------------------
					@include media-breakpoint-up(md) {
						min-width: 250px;
					} // /@include media-breakpoint-up(md)
				}
				&:last-child {
					min-width: 100px;
				}
			}// /td
		}// /tbody

	}// /&.up-table
	thead {
		th {
			border-top: 0;
		}// /th
	}// /thead
	tbody {
		background: $greyLighter;
		tr {
			&:nth-child(odd) {
				td{
					background: $sap-snow-white;
				}// /td
			}// /&:nth-child(odd)
			// Update table (used in Partner - Mass upload)
			td {
				transition: background-color $anim-dur ease-in-out;
			}
			&.updated {
				td {
					background-color: $blueLighter;
				}// /td
			}// /&.updated
		}// /tr
	}// /tbody

	th,
	td {
		background: $white;
		border-left: $borderWidthThin solid $greyLightest;
		border-bottom: $borderWidthThin solid $greyLightest;
		line-height: $baseLineHeight;
		padding: 1em;
		text-align: left;
		vertical-align: top;

		&:first-child {
			border-left: none;
			padding-left: $spaceMicroSmall;
		}// /&:first-child
	}// /th, td

	th {
		border-top: $borderWidthThin solid $greyLightest;
		@include font-bentonsans;
		font-size: $font-regular;
		// font-weight: normal;
		line-height: $lineHeightTighter;
	}
	td {
		font-size: $font-smaller;
	}

	// Special table (used in Devliery Method pages)
	// --------------------------------------------

	&.table-special {

		th {
			line-height: $lineHeightTight;

			a {
				// .small;
				display: block;
				@include font-bentonsans;
				font-size: $font-smaller;
			}
		}// / th

		td {
			color: $sap-green-medium;

			&:first-child {
				color: $grey;
			}
		}// /td

		&.four-columns {
			th {
				width: 19%;
			}
		}// /&.four-columns

		&.five-columns {
			th {
				width: 15%;
			}
		}// /&.five-columns

		// Displays text in grey, not green, etc.
		.standard {
			th {
			  // text-transform: none;
			  // background: #7F7F7F; // declared explicitly for IE
			  // #gradient > .vertical(darken($greyLight, 15%), darken($greyLight, 5%));
			}

			td {
				font-size: $font-smaller;
				color: inherit;

				&:first-child {
					color: $grey;
				}
			}
		}// /.standard
	}// /.table-special

}// /&.table



.table-alternate {
	background: $white;
	&.no-border-collapse {
		border-collapse:separate;
		border-left:none;
	}
	th {
		padding: ($baseLineHeight / 2) ($baseLineHeight * 0.75);
		background: darken($white, 5%);
	}

	td {
		padding: $baseLineHeight * 0.75;
		border-top: 1px solid $greyLighter;
	}

	th,
	td {
		border-left: 1px solid $greyLighter;
	}

	tbody td {
		background-color: $white !important;
	}

	.course {
		margin-bottom: 0;
	}

	.course-overview {
		border-bottom: none;
	}

	&.flatten td {
		padding: ($baseLineHeight / 4) ($baseLineHeight / 2);
	}

}// /.table-alternate


// File upload
//_______________________________________________________________________________________________

.file {
	input {
		min-height: 35px;
		min-width: 100%;
	}
}
.file-custom {
	&:after {
		content: attr(data-content);
	}
}// /.file-custom


// Pagination
//_______________________________________________________________________________________________

.pagination {
  margin:1em 0 2em;
  a {
  	background-color: $whiteDarker;
  	color: $greyDark;
      display:inline-block;
      padding:0.2em 0.5em;
      text-align:center;
      &:hover {
          background: $greyLighter;
          color: $greyDark;
          text-decoration:none;
      }
  }
  .current {
  	a {
        color: $white;
        background-color: $greyDarkest;
        padding:0.2em 0.5em;
  	}
  }
}


// New custom Checkbox styles - 2017
//_______________________________________________________________________________________________

.form-control {
	border-radius: 0;
	box-shadow: inset 0 1px 3px rgba(0,0,0,.2);
}
/* Base for label styling */
.custom-checkbox,
.custom-radio {
	[type="checkbox"]:not(:checked),
	[type="checkbox"]:checked,
	[type="radio"]:not(:checked),
	[type="radio"]:checked {
		position: absolute;
		left: -9999px;
	}
	[type="checkbox"]:not(:checked) + label,
	[type="checkbox"]:checked + label,
	[type="radio"]:not(:checked) + label,
	[type="radio"]:checked + label {
		position: relative;
		padding-left: 1.95em;
		cursor: pointer;
	}

	[type="checkbox"]:not(:checked) + label:before,
	[type="checkbox"]:checked + label:before,
	[type="radio"]:not(:checked) + label:before,
	[type="radio"]:checked + label:before {
		background: $white;
		border: solid 1px $greyLight;
		box-shadow: inset 0 1px 3px rgba(0,0,0,.2);
		content: '';
		height: 1rem;
		left: 0;
		position: absolute;
		top: 0rem;
		width: 1rem;
	}

	[type="checkbox"]:not(:checked) + label:before,
	[type="checkbox"]:checked + label:before {
		border-radius: 2px;
	}
	[type="radio"]:not(:checked) + label:before,
	[type="radio"]:checked + label:before {
		border-radius: 100%;
		top: .25rem;
	}

	/* checked mark aspect */
	[type="checkbox"]:not(:checked) + label:after,
	[type="checkbox"]:checked + label:after {
		color: $sap-blue-medium;
		content: '\e5ca';
		@include icon-style;
		font-size: 1.2rem;
		font-weight: bold;
		line-height: 0.8;
		position: absolute;
		top: -.05rem;
		left: 0rem;
		transition: all .2s;
	}

	/* radio checked mark aspect */
	[type="radio"]:not(:checked) + label:after,
	[type="radio"]:checked + label:after {
		background-color: $sap-blue-medium;
		border-radius: 100%;
		content: '';
		@include icon-style;
		height: .5rem;
		width: .5rem;
		position: absolute;
		top: .5em;
		left: .25em;
		transition: all .2s;
	}

	/* checked/radio mark aspect changes */
	[type="checkbox"]:not(:checked) + label:after,
	[type="radio"]:not(:checked) + label:after {
		opacity: 0;
		transform: scale(0);
	}

	[type="checkbox"]:checked + label:after,
	[type="radio"]:checked + label:after {
		opacity: 1;
		transform: scale(1);
	}

	/* disabled checkbox/radio */
	[type="checkbox"]:disabled:not(:checked) + label:before,
	[type="checkbox"]:disabled:checked + label:before,
	[type="radio"]:disabled:not(:checked) + label:before,
	[type="radio"]:disabled:checked + label:before {
		box-shadow: none;
		border-color: #d8d8d8;
	}

	[type="checkbox"]:disabled:checked + label:after,
	[type="radio"]:disabled:checked + label:after {
			color: #999;
	}

	[type="checkbox"]:disabled + label,
	[type="radio"]:disabled + label {
		color: #aaa;
	}

	/* accessibility */
	[type="checkbox"]:checked:focus + label:before,
	[type="checkbox"]:not(:checked):focus + label:before,
	[type="radio"]:checked:focus + label:before,
	[type="radio"]:not(:checked):focus + label:before {
		box-shadow: 2px dotted $sap-blue-bright;
	}
}
.no-label {
	label {
		margin-bottom: 0;
	}
	[type="checkbox"]:not(:checked) + label,
	[type="checkbox"]:checked + label {
		min-height: 1rem;
		min-width: 1rem;
		padding-left: 0;
	}

	[type="radio"]:not(:checked) + label,
	[type="radio"]:checked + label {
		min-height: 1rem;
		min-width: 1rem;
		padding-left: 0;
		&:before {
			top: 0.1em;
		}
		&:after {
			top: .45em;
			left: .3em;
		}
	}
}


// sitewide custom classes
h3.alert-header {
  padding-left: 2em;
  padding-right: 1em;
}
.header-icon {
  font-size: $font-large;
}
.vertical-align {
	display: flex;
    align-items: center;
}
.block-grey {
  border: 1px solid $greyLightest;
  padding: 10px;
  background-color: #f4f4f4;
	word-wrap: break-word;
}

// TinyMce override
.form-group {
	.mce-tinymce {
		max-width: 100%;
		width: 100% !important;
	}
	div.mce-edit-area {
		border-width: 1px 1px 0px 0px !important;
	}
}

// Credential manager - User profile
//_______________________________________________________________________________________________
.up-content {
	min-height: 50vh;
	padding-bottom: 2rem;
}
.up-header {
	background-color: $greyDark;
	position: relative;
	height: rem(134px);
	padding: 0;
	margin-bottom: rem(48px);
	&__avatar {
		border: 3px solid $white;
    border-radius: 50%;
		background-color: #919598;
		top: rem(69px);
    margin-left: rem(20px);
    width: rem(96px);
		height: rem(96px);
		overflow: hidden;
		text-align: center;
		&.up-sidenav {
			margin-left: rem(20px);
			width: rem(96px);
			height: rem(96px);
			// lg up (> 992px) ---------------------------------------------------------------------------------
			@include media-breakpoint-up(lg) {
				top: 5px;
				margin-left: 50px;
				width: rem(160px);
				height: rem(160px);
			} // /@include media-breakpoint-up(lg)

			// xl up (> 1200px) --------------------------------------------------------------------------------
			@include media-breakpoint-up(xl) {
				margin-left: calc(((100vw - 800px) / 2) - 200px);
			} // /@include media-breakpoint-up(xl)

			// xxl up (> 1400px) --------------------------------------------------------------------------------
			@include media-breakpoint-up(xxl) {
				margin-left: calc(((100vw - 980px) / 2) - 200px);
			} // /@include media-breakpoint-up(xxl)
		}

		&-edit {
			position: absolute;
			bottom: 0;
			width: 100%;
			height: 28px;
			background-color: rgba(0,0,0,0.6);
			&-link {
				width: 100%;
				line-height: 28px;
				display: block;
				text-align: center;
				text-decoration: none;
				color: $white;
				&:active,
				&:focus,
				&:hover {
					color: $white;
					text-decoration: underline;
				}
			}
		}
	}
	.up-container {
		position: absolute;
		// lg up (> 992px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(lg) {
			position: relative;
		} // /@include media-breakpoint-up(lg)
	}
	.up-page-content {
		width: 100%;
		font-size: 10px;
		padding-top: 5px;
		// lg up (> 992px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(lg) {
			padding-top: rem(60px);
			width: 60%;
			left: rem(10px);
		} // /@include media-breakpoint-up(lg)
	}
	.up-page-title {
		margin-left: 20px;
    font-size: 20px;
    line-height: 50px;
		color: $white;
		// lg up (> 992px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(lg) {
			font-size: 40px;
			line-height: 40px;
		} // /@include media-breakpoint-up(lg)
		&__span {
			display: none;
			&.show {
				display: inline-block;
			}
		}
	} // /.up-page-title


	&__actions {
		position: absolute;
    top: 0px;
		right: 10px;
		padding-top: rem(24px);
		max-width: rem(180px);
		// md up (> 768px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(md) {
			max-width: none;
			padding-top: rem(44px);
			.btn {
				font-size: 13px;
				padding: 11px 21px;
				&:first-child {
					margin-right: 20px;
				}
			}
		} // /@include media-breakpoint-up(md)


		.btn {
			font-size: 12px;
			margin-bottom: 10px;
			padding: 10px 16px;
		}
	} // /&__actions
} // /.up-header

.up-sidenav {
	position: absolute;
	margin-left: calc(((100vw - 980px) / 2) - 200px);
	font-size: 14px;
	width: 200px;
	color: #666;
	right: auto;

	// lg up (> 992px) ---------------------------------------------------------------------------------
	@include media-breakpoint-up(lg) {
		margin-left: 50px;
	} // /@include media-breakpoint-up(lg)

	// xl up (> 1200px) --------------------------------------------------------------------------------
	@include media-breakpoint-up(xl) {
		margin-left: calc(((100vw - 800px) / 2) - 200px);
	} // /@include media-breakpoint-up(xl)

	// xxl up (> 1400px) --------------------------------------------------------------------------------
	@include media-breakpoint-up(xxl) {
		margin-left: calc(((100vw - 980px) / 2) - 200px);
	} // /@include media-breakpoint-up(xxl)
	&__list {
		padding: 50px 20px;
		// lg up (> 992px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(lg) {
			padding: 0;
		} // /@include media-breakpoint-up(lg)

		.up-sidenav.up-scroll & {
			height: 100%;
			position: relative;
			overflow-y: scroll !important;
			-ms-overflow-style: none;
		}
	}
	&__item {
		margin-bottom: rem(18px);
		transition: color $anim-dur ease-in-out;
		&--active {
			color: $sap-gold-dark;
		}
		&-label {
			display: inline-block;
			cursor: pointer;
			line-height: 30px;
			padding-left: 10px;
			width: calc(100% - 34px);
			.up-sidenav__item--children & {
				margin-bottom: 10px;
			}
		}
		.up-icon {
			display: block;
			float: left;
			font-size: 24px;
			line-height: 24px;
			width: 24px;
			i {
				line-height: 30px;
			}
		}

	} // /&__item
	&__sublevel {
		list-style-type: none;
		padding-left: 34px;
		// lg up (> 992px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(lg) {
			display: none;
		} // /@include media-breakpoint-up(lg)
		li {
			color: $greyDarker;
			line-height: 18px;
			font-size: rem(12px);
			margin-bottom: 10px;
			position: relative;
			&.up-active {
				font-weight: bold;
				&:before {
					background-color: $sap-gold-dark;
					display: block;
					content: '';
					height: 100%;
					width: 2px;
					position: absolute;
					right: calc(100% + 10px);
				}
			}
			&:before {
				display: none;
			}
		}
	}
	&__subitem {
		cursor: pointer;
	}
	&.up-scroll {
		max-height: calc(100vh - 65px - 20px - 5px);
		padding-bottom: 20px;
		transition: all $anim-fast ease-in-out;
		&:after {
			content: "";
			position: absolute;
			z-index: 1;
			height: 50px;
			bottom: 0;
			pointer-events: none;
			background-image: linear-gradient(to bottom, rgba(255,255,255,0), #fff 85%);
			width: 100%;
			color: transparent;
		}
	}
	&.sticky {
		position: fixed;
    top: 0;
    left: 0;
    overflow-x: hidden;
    margin-top: 20px;
	}
}
.up-container {
	margin: 0 auto;
	width: 100vw;
	position: relative;
	// lg up (> 992px) ---------------------------------------------------------------------------------
	@include media-breakpoint-up(lg) {
		width: calc(100vw - 260px);
    margin-right: 20px;
	} // /@include media-breakpoint-up(lg)

	// xl up (> 1200px) --------------------------------------------------------------------------------
	@include media-breakpoint-up(xl) {
		width: 800px;
		margin: 0 auto;
	} // /@include media-breakpoint-up(xl)

	// xxl up (> 1400px) --------------------------------------------------------------------------------
	@include media-breakpoint-up(xxl) {
		width: 980px;
		margin: 0 auto;
	} // /@include media-breakpoint-up(xxl)
}
.up-page-content {
	width: 100%;
	top: 0;
	font-size: 18px;
	color: #333;
}

.up-mobilenav {
	top: 0;
	left: -100%;
	transition: left $anim-dur ease-in-out;
	background-color: $white;
	z-index: 99999;
	overflow-x: hidden;
	position: fixed;
	width: 100%;
	height: 100%;
	&.is-open {
    left: 0;
    transition: left .3s ease-out;
		.up-close-mobile-nav {
			display: block;
		}
	}
	.up-close-mobile-nav {
    position: relative;
    display: none;
    cursor: pointer;
    top: 20px;
		right: 20px;
		float: right;
	}
}
.up-close-mobile-nav {
	// lg up (> 992px) ---------------------------------------------------------------------------------
	@include media-breakpoint-up(lg) {
		display: none;
} // /@include media-breakpoint-up(lg)
}
.up-button-sidenav {
	font-size: 13px;
	color: #ffffff;
	background-color: #000;
	border-color: #000;
	position: fixed;
	top: 210px;
	right: 15px;
	z-index: 10000;
	border-radius: 25px;
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
	// lg up (> 992px) ---------------------------------------------------------------------------------
	@include media-breakpoint-up(lg) {
		display: none;
	} // /@include media-breakpoint-up(lg)
}


.up-content-section {
	display: none;
	&.show {
		display: block;
	}
}

// About section
.up-profile {
	color: $greyDarker;
	&__label {
		font-size: rem(12px);
		line-height: rem(18px);
		margin-bottom: 0;
	}
	&__data {
		color: $greyDarker;
		font-size: rem(16px);
		line-height: rem(24px);
		margin-bottom: rem(22px);
	}
}

// Certification - load more
.up-load-more__item {
	display: none;
}

// Reputation section
.up-reputation {
	&__points {
		font-size: rem(16px);
		line-height: rem(24px);
		margin-bottom: rem(34px);
	}
}

// Status section
.up-tabs {
	position: relative;
	// lg up (> 992px) ---------------------------------------------------------------------------------
	@include media-breakpoint-up(lg) {
		margin-top: 4rem;
		border-top: 2px solid $greyLightest;
	} // /@include media-breakpoint-up(lg)
	&__trigger {
		border-bottom: 2px solid $greyLightest;
		display: block;
		font-size: 14px;
		color: $greyDarker;
		line-height: 21px;
		width: 100%;
		position: relative;
		margin-bottom: 20px;
		padding-right: 1.5rem;
		&:active,
		&:focus,
		&:hover {
			color: $greyDarker;
		}
		&:after {
			content: "\e92f";
			@include icon-family;
			position: absolute;
			right: 0;
		}
		&--active {
			border-bottom: 2px solid $sap-gold-dark;
			font-weight: bold;
			&:after {
				content: "\e932";
			}
		}
		// lg up (> 992px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(lg) {
			position: absolute;
			top: - 32px;
			width: auto;
			left: 0;
			padding-right: 0;
			line-height: 30px;

			&:nth-child(3){
				left: 230px;
			}
			&:nth-child(5){
				left: 376px;
			}
			&:after {
				display: none;
			}
			@include firefox {
				letter-spacing: -.5px;
			}
			@include safari-alt {
				letter-spacing: -1px;
			}
		} // /@include media-breakpoint-up(lg)
	}
	&__content {
		height: 0;
    overflow: hidden;
    position: relative;
    transition: height 500ms ease-in-out;
    width: 100%;
		will-change: height;
		&--active {
			height: auto;

		}
		// lg up (> 992px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(lg) {
			display: none;
      left: 0;
      position: absolute;
      top: 0;
      transition: none;
      width: 100%;
      z-index: -1;

      &--active {
				display: block;
				height: auto;
				position: relative;
				z-index: 1;
      }
		} // /@include media-breakpoint-up(lg)
		&-inner {
			margin-bottom: rem(30px);
			&--margin {
				margin-top: 0;
				// lg up (> 992px) ---------------------------------------------------------------------------------
				@include media-breakpoint-up(lg) {
					margin-top: rem(24px);
				} // /@include media-breakpoint-up(lg)
			}
		}
	}
}
// Downtime management
//_______________________________________________________________________________________________
.live-chat {
	background-color: $chat-grey;
	padding: 2rem 0;
	// md up (> 768px) ---------------------------------------------------------------------------------
	@include media-breakpoint-up(md) {
		background-color: $chat-blue;
		max-width: 620px;
		padding: 0;
		position: fixed;
		right: 0;
		top: 50%;
		transform: translateY(-50%) translateX(100%);
		transition: transform $anim-dur ease-in-out;
		z-index: 101;
		&__toggle-icon {
			font-size: 26px;
			line-height: 1;
			text-align: center;
		}
		&--open {
			transform: translateY(-50%) translateX(0%);
			.live-chat__toggle-button {
				transform: translateX(0%) translateY(-50%);
				opacity: 0;
			}
		}
	} // /@include media-breakpoint-up(md)

	&__toggle-button {
		display: none;
		// md up (> 768px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(md) {
			background-color: $chat-blue;
			color: $white;
			cursor: pointer;
			display: block;
			font-size: 14px;
			left: 0%;
			padding: .75rem 1.5rem;
			position: absolute;
			top: 50%;
			transform: translateX(-100%) translateY(-50%);
			transition: all $anim-dur ease-in-out;
			width: auto;
		} // /@include media-breakpoint-up(md)
	} // /&__toggle-button


	&__link {
		color: $greyLight;
		line-height: 1.2;
		&:active,
		&:hover,
		&:visited {
			color: $greyLight;
			text-decoration: underline;
		}
		// md up (> 768px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(md) {
			color: $chat-blue;
			&:active,
			&:hover,
			&:visited {
				color: $chat-blue;
				text-decoration: underline;
			}
			.live-chat__column:first-child & {
				color: $white;
				&:active,
				&:hover,
				&:visited {
					color: $white;
					text-decoration: underline;
				}
			}
		} // /@include media-breakpoint-up(md)
	} // /&__link

	&__contact {
		color: $greyLight;
		display: inline-block;
		margin-bottom: 1rem;
		width: 100%;
		// md up (> 768px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(md) {
			color: $white;
			float: left;
			padding: 28px 24px 27px;
			border-bottom: 1px solid $chat-border;
			margin-bottom: 0;
			.live-chat__column:first-child & {
				padding: 55px 24px;
			}
		} // /@include media-breakpoint-up(md)
		&-icon {
			float: left;
			margin: 0 15px 0 0;
			width: 32px;
			height: 32px;
			.mobile {
				// md up (> 768px) ---------------------------------------------------------------------------------
				@include media-breakpoint-up(md) {
					display: none;
				} // /@include media-breakpoint-up(md)
			}
			.desktop {
				display: none;
				// md up (> 768px) ---------------------------------------------------------------------------------
				@include media-breakpoint-up(md) {
					display: block;
				} // /@include media-breakpoint-up(md)
			}
		}
		&-text {
			float: right;
			width: calc(100% - 47px);
			margin-top: 4px;
			p {
				margin-bottom: 0;
			}
			.live-chat__column:first-child & {
				width: 100%;
			}
			// md up (> 768px) ---------------------------------------------------------------------------------
			@include media-breakpoint-up(md) {
				margin-top: 0;

			} // /@include media-breakpoint-up(md)
		} // /&-text

		&-chat {
			&-online {
				display: none;
			}
			&-offline {
				display: block;
			}
			&--online {
				.live-chat__contact-chat-online {
					display: block;
				}
				.live-chat__contact-chat-offline {
					display: none;
				}
			}
		} // /&-chat
		&:last-child {
			// md up (> 768px) ---------------------------------------------------------------------------------
			@include media-breakpoint-up(md) {
				border-bottom: 0px solid $chat-border;
			} // /@include media-breakpoint-up(md)
		}
		&-bot-unavailable {
			display: none;
		}
	} // /&__contact

	&__other-text {
		p {
			color: $greyLight;
			font-size: 12px;
			a {
				text-decoration: underline;
			}
			// md up (> 768px) ---------------------------------------------------------------------------------
			@include media-breakpoint-up(md) {
				color: $white;
			} // /@include media-breakpoint-up(md)
		}
	}
	&__column {
		&:first-child {
			background-color: transparent;
		}
		// md up (> 768px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(md) {
			background-color: $sap-snow-white;
			padding: 0;
		} // /@include media-breakpoint-up(md)
	}// /&__column
	&-bg {
		display: none;
		// md up (> 768px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(md) {
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: transparent;
			z-index: 100;
			pointer-events: none;
			&--open {
				pointer-events: all;
				display: block;
			}
		} // /@include media-breakpoint-up(md)
	}// /&-bg
}// /.live-chat
