/* -------------------------------------------------------------------------------------------------
 * SAP - Variables
 * ---------------------------------------------------------------------------------------------- */

// Files Paths
$static-path: 	'..';
$icon-path: 	'#{$static-path}/fonts';
$font-path: 	'#{$static-path}/fonts/new';
$image-path:    '#{$static-path}/images';

// Colours
// ------------------------------------------------------------------------------------------------

// greys
$white:                 	rgb(255, 255, 255);
$whiteDarker:           	rgb(230, 230, 230);

$greyLightest:          	rgb(215, 215, 215);
$greyLighter:           	rgb(204, 204, 204);
$greyLight:             	rgb(153, 153, 153);
$greyDark:              	rgb(56, 56, 56);
$greyDarker:              #333;
$greyDarkest:           	rgb(34, 34, 34);
// Side panels
$sap-snow-white: 				  #F9FAFB;
$sap-snow-grey:					  #F1F4F5;
$black:                	  rgb(0, 0, 0);
$chat-grey:               #222;
$chat-border:             #ddd;
$chat-blue:               #2fa0e0;

// Alpha
$blackAlphaLight:			    rgba(0, 0, 0, 0.3);
$blackAlpha:					    rgba(0, 0, 0, 0.5);
$blackAlphaDark:				  rgba(0, 0, 0, 0.6);
$blackAlphaOpaque:      	rgba(0, 0, 0, 0.8);
$whiteAlphaOpaque:      	rgba(255, 255, 255, 0.9);
$whiteAlphaOpaquer:      	rgba(255, 255, 255, 0.6);


// Accent colors
$blueLighter:					    rgb(209, 226, 230);
$blueDark:						    rgb(38, 50, 56);
$yellow:                	rgb(241, 183, 56);


// SAP Brand Colours - used in various tables (Possibly Learner Network)
$greenBrand:              rgb(104, 172, 22);
$greyGreenBrand:          rgb(237, 242, 230);
$orangeBrand:             rgb(229, 84, 14);
$greyOrangeBrand:         rgb(247, 234, 226);
$purpleBrand:             rgb(110, 0, 109);
$greyPurpleBrand:         rgb(241, 242, 243);


// SAP Homepage quicklink colors
$sap-gold: 						   #f0ab00;
$sap-gold-dark:          #eea102;

// Blue
$sap-blue-bright: 			 #008fd3;
$sap-blue-medium: 			 #0076cb;
$sap-blue-deep:					 #003283;
$sap-blue-up:            #2fa0e0;
// Blue hover
$sap-blue-hover:				 lighten($sap-blue-medium, 10);

// Light blue on dark background
$sap-link-light:         #7AD5FF;

// Purple
$sap-purple-bright:			 #970a82;
$sap-purple-medium:			 #760a85;
$sap-purple-deep:				 #45157e;

// Orange
$sap-orange-bright:			 #eb7300;
$sap-orange-medium:			 #e35500;
$sap-orange-deep:				 #bc3618;
$sap-orange-warning:     #f9e5b9;
$sap-brown:              #d89613;

// Red
$sap-red-medium:				 #bc3618;

// Green
$sap-green-bright:			 #93c939;
$sap-green-medium:			 #4fb81c;
$sap-green-deep:				 #247230;

// SAP Social Link colors
$sap-facebook:					 #3b5998;
$sap-twitter:					   #1da1f2;
$sap-linkedin:					 #0077b5;
$sap-youtube:					   #cd201f;


// SAP Attendee colors
$sap-attendee-green:		 #4fb81d;
$sap-attendee-yellow:		 #efab00;

// Next Gen - Student edition
$box-shadow: rgba(0,0,0,.16);
$next-gen-border: #727272;
$next-gen-blue: #007DB8;
$next-gen-green: #4FB81C;
$next-gen-red: #A4360E;
$next-gen-yellow: #F5A623;

// Typography
// -------------------------------------------------------------------------------------------------

// Font weights
$font-weights: (
    thin:       100,
    extralight: 200,
    light:      300,
    regular:    normal,
    medium:     500,
    semibold:   600,
    bold:       bold,
    extrabold:  800,
    heavy:      900
);

// Font sizes

$font-base: 16px !default; // Used for px to em mixin

$font-11px:             11px;     // always 11px
$font-tiny: 					  14px;     // always 14px
$font-smaller:				  .65rem;	  // 12px
$font-small:					  .75rem;	  // 14px
$font-regular:				  .875rem;	// 16px
$font-16px:             1rem;  // 16px
$font-20px:             1.25rem;  // 20px
$font-medium:					  1.5rem;   // 24px
$font-large:   				  2rem;     // 32px
$font-larger:					  3.375rem;	// 54px

// Font size - 2021
/**
* Usage: @include font-size($text-h4);
* See _type.scss for a list of available helper classes to use.
*/
$text-h1: (
  null: (59.41px, 1.1), // default
  md: (39.81px, 1.1),
);

$text-h2: (
  null: (45.70px, 1.2), // default
  md: (33.18px, 1.2),
);

$text-h3: (
  null: (35.15px, 1.2), // default
  md: (27.65px, 1.2),
);

$text-h4: (
  null: (27.04px, 1.3), // default
  md: (23.04px, 1.3),
);

$text-h5: (
  null: (20.80px, 1.3), // default
  md: (19.20px, 1.3),
);

$text-h6: (
  null: (16px, 1.3), // default
);

$text-body: (
  null: (16px, 1.5), // default
);

$text-body-lg: (
  null: (20.80px, 1.5), // default
  md: (19.20px, 1.5),
);

$text-body-sm: (
  null: (12.31px, 1.5), // default
  md: (13.33px, 1.5),
);


// Line Height
$lineHeightTightest:		    1;
$lineHeightTighter:			    1.25;
$lineHeightTight:           1.5;
$lineHeight:                1.571;
$lineHeightLoose:				    1.75;

// $linkColor:             		$blueAlphaOpaquer;
// $linkColorHover:        		darken($linkColor, 5%);
$linkColorHoverDecoration: 	underline;
$baseLineHeight:       			20px;


// Spacing
// --------------------------------------------------
$spaceMicroSmallest:		5px;
$spaceMicroSmaller:			10px;
$spaceMicroSmall:				20px;
$spaceMicro:					  35px;
$spaceMicroLarge:				40px;

$spaceMacroSmaller:			50px;
$spaceMacroSmall:				60px;
$spaceMacro:					  70px;
$spaceMacroLarge:				80px;
$spaceMacroLarger:			90px;
$spaceMacroLargest:			100px;


// Borders & HR
// --------------------------------------------------
$borderWidthThin:				1px;
$borderWidth:					  2px;
$borderWidthThick:			4px;

$borderRadiusNone:			0;
$borderRadius:					4px;
$borderRadiusLarge:			8px;


// Depth
// --------------------------------------------------
$depth1:                100;
$depth2:                200;
$depth3:                300;

$zindexDropdown:        1000;
$zindexFixedNavbar:     1020;
$zindexModalBackdrop:   1030;
$zindexModal:           1040;
$zindexTooltip: 				1050;


// Buttons
// --------------------------------------------------
$btnInfoBackground:             rgb(91, 192, 222);
$btnInfoBackgroundHighlight:    rgb(47, 150, 180);

$btnSuccessBackground:          rgb(98, 196, 98);
$btnSuccessBackgroundHighlight: rgb(81, 163, 81);


// Form States
// --------------------------------------------------
$warningText:             	rgb(192,152,83);


// Grid
// --------------------------------------------------
$gridColumns:           		12;
$gridColumnWidth:       		60px;
$gridGutterWidth:       		20px;
$gridRowWidth:          		($gridColumns * $gridColumnWidth) + ($gridGutterWidth * ($gridColumns - 1));

// Fluid grid
$fluidGridColumnWidth:    	6.382978723%;
$fluidGridGutterWidth:    	2.127659574%;


// Layout variables
// --------------------------------------------------
// $containerMaxWidth:				1180px;
// $browseButtonWidth:				27.5%;
// $searchBarWidth:				  70.5%;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.

$spacer:                     1rem !default;
$spacer-x:                   $spacer !default;
$spacer-y:                   $spacer !default;
$spacers: (
  0: (
    x:   0,
    y:   0
  ),
  1: (
    x:   $spacer-x,
    y:   $spacer-y
  ),
  2: (
    x:   ($spacer-x * 1.5),
    y:   ($spacer-y * 1.5)
  ),
  3: (
    x:   ($spacer-x * 3),
    y:   ($spacer-y * 3)
  ),
  4: (
    x:   ($spacer-x * 4),
    y:   ($spacer-y * 4)
  ),
  5: (
    x:   ($spacer-x * 5),
    y:   ($spacer-y * 5)
  ),
  6: (
    x:   ($spacer-x * 6),
    y:   ($spacer-y * 6)
  ),
  7: (
    x:   ($spacer-x * 7),
    y:   ($spacer-y * 7)
  ),
  8: (
    x:   ($spacer-x * 8),
    y:   ($spacer-y * 8)
  )
) !default;

$half-grid-gutter-width:          (1.875rem / 4); // 30px

// Animations
// -------------------------------------------------------------------------------------------------
$anim-dur: .25s;
$anim-fast: .15s;

// Height variables
// -------------------------------------------------------------------------------------------------
$header-height: 69px;
$help-centre-footer-height: 70px;
$footer-height: 74px;

// Display options
$display-modes: (
  none:none,
  inline:inline,
  inline-block:inline-block,
  block:block,
  table:table,
  table-row:table-row,
  table-cell:table-cell,
  flex:flex,
  inline-flex:inline-flex
)