/* -------------------------------------------------------------------------------------------------
 * SAP - Sitewide layout - Footer
 * ---------------------------------------------------------------------------------------------- */

// FOOTER
//_______________________________________________________________________________________________
.footer {
	background: #e9e9e9;
	bottom: 0;
	color: black;
	font-size: 12px;
	left: 0;
	line-height: 1.1416;
	padding: 19px 0 20px;
	width: 100%;
	.menu {
		top: 3px;
		.link {
			display: inline-block;
			margin: 4px 0;
			padding: 0 8px;
			text-decoration: none;
			width: 100%;
			text-align: center;

			// md up (> 768px) ---------------------------------------------------------------------------------
			@include media-breakpoint-up(md) {
				width: auto;
				text-align: left;
				&:first-child {
					margin-left: 0;
				}// /&:first-child
			} // /@include media-breakpoint-up(md)

		}
		a {
			color: black;
			font-family: Arial, Helvetica, sans-serif;
		}
	}
	.socialLinksWrap {
		.socialLinksGroup {
			width: 100%;
			text-align: center;
			// md up (> 768px) ---------------------------------------------------------------------------------
			@include media-breakpoint-up(md) {
				width: auto;
				text-align: left;
			} // /@include media-breakpoint-up(md)
			.nav {
				li {
					margin: 0 5px;
					a {
						img {
							height: 24px;
							width: 24px;
						}
					}
				}
			}
			ul {
				display: inline-block;
				list-style: none;
				margin: 0;
				padding: 0;

				li {
					display: inline-block;
					list-style: none;
					margin: 0;
					padding: 0;
				}
			}
			.headline {
				display: inline-block;
				@include font-bentonsans;
				font-weight: bold;
				font-size: 15px;
				margin-bottom: 1em;
			}
		}// /socialLinksGroup
	}// /.socialLinksWrap

	
}// /#Footer

