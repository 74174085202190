// Normalize Styles
@import "node_modules/normalize.css/normalize";

// SAP Main CSS build
// -------------------------------------------------------------------------------------------------

// Core variables and mixins
@import "imports/variables";
@import "imports/functions";
@import "imports/mixins";

// Bootstrap
@import "imports/bootstrap/bootstrap";

// Core global
@import "imports/type";
@import "imports/animations";
@import "imports/transitions";
@import "imports/utility";

// Sitewide elements
@import "imports/layout";
@import "imports/widgets";
@import "imports/social";

// Page-specific
@import "imports/page-specific";
@import "imports/page-specific-cp";

// Third-party overrides
@import "imports/third-party";

// Third-party overrides
@import "imports/third-party";

.landing-menu > li {
  float: none;
}

ul.landing-menu li {
  display: block;
}

ul.landing-menu li:before {
  content: "";
}

.nav-icons {
  top: 4px!important;
}

html, body{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.m-b-0 {

}
.modal-header h3 {
  font-size: 1.688rem;
  font-weight: 430;
}
.modelPopup-header{
  font-family: BentonSans, Reguar !important;
  font-size: 1.25rem;
  font-weight: 400;
  margin-bottom: -9px!important;
  margin-top: 13px!important;
}

newlxp-footer{
  margin-top: auto;
}

div.info-container.container {
  padding-top: 84px;
  padding-bottom: 84px;
}

div.info-container .info-image {
  padding-right: 52px;
  padding-left: 0;
}

div.info-container .info-text {
  margin-top: 12px;
  padding-left: 0;
}

@media only screen and (min-width: 376px) {
  /* For tablets?: */
  .modelPopup-iframe {
    min-height: 517px !important;
  }
}
@media only screen and (max-width: 420px) {
  /* For phone?: */
  .close {
      float: right;
      font-size: 3rem !important;
      font-weight: 320;
      line-height: 1;
      color: #000;
      text-shadow: 0 1px 0 #fff;
      opacity: .2;
      margin-top:-10px !important;
  }
  .m-b-0 {

  }
  .brand-LineBreak {
      display: inline-block;
  }
  .modelPopup-iframe {
    min-height: 182px !important;
  }
  .modelPopup-header {
    font-family: BentonSans, Reguar !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    margin-bottom: -0px!important;
    margin-top: 4px!important;
  }
  .modelPopup-p {
    font-family: BentonSans-Book!important;
    font-size: 14px !important;
  }
  .modelPopup-checkBox-text {
    font-family: BentonSans, Book!important;
    font-size: 14px !important;
  }
  h3.m-b-1.modelPopup-header {
      display: none;
  }
  .modelPopup-header-welcome {
      display: none;
  }
  div.info-container.container {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
.onboarding .embed-container {
    margin-top: 0.3em;
    margin-left: -1.25em;
    margin-right: -1.25em;
}
.modelPopup-p{
  font-family: BentonSans-Book!important;
  font-size: 1rem;
}
.modelPopup-checkBox-text{
  font-family: BentonSans, Book!important;
  font-size: 0.875rem;
  margin-left: 31px;
}
.modal-checkbox-regular {
  width: 1.25em !important;
  position: fixed !important;
  height: 1.25em !important;
  margin-top: 1px !important;
}
.modal-header-newFont {
  padding: .75rem 1.75rem !important;
  border-bottom: 1px solid #e5e5e5;
}
// ipad horizonal view margin changes
@media (min-width: 992px) and (max-width: 1199px) {
  .home-tiles .container {
      padding-left: 0.9375rem;
      padding-right: 0.9375rem;
  }
}
@media (min-width: 768px) and (max-width: 900px) {
  .modal-lg {
      width: 600px!important;
      margin: 30px auto!important;
  }
}
//--------- home page container part
@media (min-width: 360px) and (max-width: 767px){
  .containerLoggedOut {
      max-width: 767px!important;
      margin: 0 13px!important;
      padding-left:15px!important;
      padding-right:15px!important;
  }

  div.info-container.container {
    padding-top: 52px;
    padding-bottom: 52px;
  }
}
@media (min-width: 768px) and (max-width: 979px){
  .containerLoggedOut {
      max-width: 1000px!important;
      margin: 0 40px!important;
      padding-left:15px!important;
      padding-right:15px!important;
  }
}
@media (min-width: 980px) and (max-width: 1299px){
  .containerLoggedOut {
      max-width: 1210px!important;
      margin: 0 75px!important;
      padding-left:15px!important;
      padding-right:15px!important;
  }
}
@media (min-width: 980px) and (max-width: 1599px){
  .containerLoggedOut {
      max-width: 1510px!important;
      margin: 0 75px!important;
      padding-left:15px!important;
      padding-right:15px!important;
  }
}
@media (min-width: 1600px){
  .container--lg {
      max-width: 1450px!important;
      margin-left: auto!important;
      margin-right: auto!important;
      padding-left:15px!important;
      padding-right:15px!important;
  }
}

a .home-tiles__tile-title {
    font-family: BentonSans-Medium;
}
a .home-tiles__tile-product {
    font-family: BentonSans-Medium;
}
a .home-tiles__tile-category.home-tiles__tile-category--product {
    font-family: BentonSans-Regular;
}
#terms_div input{
  max-width:18px;
  margin-top:5px;
}

.resource-directory-descr {
  max-width: 380px;
}
