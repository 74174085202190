/* Type
-------------------------------------------------- */

// Fonts
// -------------------------
@font-face {
  font-family: 'bentonsansbold';
  src: url('#{$font-path}/BentonSans-Bold/bentonsans-bold.eot');
  src: url('#{$font-path}/BentonSans-Bold/bentonsans-bold.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/BentonSans-Bold/bentonsans-bold.svg#bentonsansbold') format('svg'),
  url('#{$font-path}/BentonSans-Bold/bentonsans-bold.woff') format('woff'),
  url('#{$font-path}/BentonSans-Bold/bentonsans-bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'bentonsansbook';
  src: url('#{$font-path}/BentonSans-Book/bentonsans-book.eot');
  src: url('#{$font-path}/BentonSans-Book/bentonsans-book.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/BentonSans-Book/bentonsans-book.svg#bentonsansbook') format('svg'),
  url('#{$font-path}/BentonSans-Book/bentonsans-book.woff') format('woff'),
  url('#{$font-path}/BentonSans-Book/bentonsans-book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face{
  font-family:'bentonsansregular';
  src:url('#{$font-path}/BentonSans-Regular/BentonSans-Regular.eot');
  src:url('#{$font-path}/BentonSans-Regular/BentonSans-Regular.eot?') format('eot'),
  url('#{$font-path}/BentonSans-Regular/BentonSans-Regular.ttf') format('truetype'),
  url('#{$font-path}/BentonSans-Regular/BentonSans-Regular.woff') format('woff'),
  url('#{$font-path}/BentonSans-Regular/BentonSans-Regular.svg#bentonsansregular') format('svg'),;
  font-weight:normal;
  font-style:normal
}

@font-face {
  font-family: 'bentonsansmedium';
  src: url('#{$font-path}/BentonSans-Medium/BentonSans-Medium.eot');
  src: url('#{$font-path}/BentonSans-Medium/BentonSans-Medium.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/BentonSans-Medium/BentonSans-Medium.svg#bentonsansmedium') format('svg'),
  url('#{$font-path}/BentonSans-Medium/BentonSans-Medium.woff') format('woff'),
  url('#{$font-path}/BentonSans-Medium/BentonSans-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'bentonsanslight';
  src: url('#{$font-path}/BentonSans-Light/bentonsans-light.eot');
  src: url('#{$font-path}/BentonSans-Light/bentonsans-light.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/BentonSans-Light/bentonsans-light.svg#bentonsanslight') format('svg'),
  url('#{$font-path}/BentonSans-Light/bentonsans-light.woff') format('woff'),
  url('#{$font-path}/BentonSans-Light/bentonsans-light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

// Font mixins
// -------------------------------------------------------------------------------------------------
// Regular
@mixin font-bentonsans {
    font-family: 'bentonsansregular', sans-serif;
} // /@mixin font-bentonsans

//Bold
@mixin font-bentonsans-bold {
    font-family: 'bentonsansbold', sans-serif;
} // /@mixin bentonsansbold

//Bold
@mixin font-bentonsans-book {
    font-family: 'bentonsansbook', sans-serif;
} // /@mixin bentonsansbold

//Bold
@mixin font-bentonsans-medium {
    font-family: 'bentonsansmedium', sans-serif;
} // /@mixin bentonsansbold



// Font Classes
// -------------------------------------------------------------------------------------------------
// Regular
.font-bentonsans {
    @include font-bentonsans;
} // /.font-bentonsans

// Bold
.font-bentonsans-bold {
    @include font-bentonsans-bold;
} // /.font-bentonsans-bold

// Book
.font-bentonsans-book {
    @include font-bentonsans-book;
} // /.font-bentonsans-bold

// Medium
.font-bentonsans-medium {
    @include font-bentonsans-medium;
} // /.font-bentonsans-bold

