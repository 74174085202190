/* -------------------------------------------------------------------------------------------------
 * SAP - Global mixins
 * ---------------------------------------------------------------------------------------------- */

// Make bootstrap mixins available to the app mixins
@import 'imports/bootstrap/mixins';

// Add custom mixins below


// Clearfix
// -------------------------------------------------------------------------------------------------

@mixin clearfix {
    *zoom: 1;

    &:before,
    &:after {
        display: table;
        content: "";
    }

    &:after {
        clear: both;
    }
}// /@mixin clearfix


// Icon
// -------------------------------------------------------------------------------------------------

@mixin icon-family {
    font-family: 'icomoon';
    src:    url('#{$icon-path}/icomoon.eot?ymyrwj');
    src:    url('#{$icon-path}/icomoon.eot?ymyrwj#iefix') format('embedded-opentype'),
        url('#{$icon-path}/icomoon.ttf?ymyrwj') format('truetype'),
        url('#{$icon-path}/icomoon.woff?ymyrwj') format('woff'),
        url('#{$icon-path}/icomoon.svg?ymyrwj#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}// /@mixin icon-family


/* -------------------------------------------------------------------------------------------------
 * Browser targeting
 * ---------------------------------------------------------------------------------------------- */
// Targeting IE10 + 11 only (as these no longer support conditional comments)
// -------------------------------------------------------------------------------------------------
@mixin ie-10-11 {
    // The logic here is that only IE 10/11 support these media queries (ignored by IE9)
    // IE 10/11
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        @content;
    }
} // /@mixin ie-10-11

// Targeting IE 9 (and below)
// -------------------------------------------------------------------------------------------------
@mixin ie-9 {
    .lt-ie10 & {
        @content;
    } // /.lt-ie10
} // /@mixin ie-10-11

// All Firefox versions
// -------------------------------------------------------------------------------------------------
@mixin firefox {
    @-moz-document url-prefix() {
        @content;
    } // /@-moz-document url-prefix()
} // /@mixin firefox

// Safari 9+
// -------------------------------------------------------------------------------------------------
@mixin safari {
    _::-webkit-:not(:root:root), &.safari-only { @content; }
} // /@mixin safari

// Safari 9+
// -------------------------------------------------------------------------------------------------

@mixin safari-alt {
    @media screen and (-webkit-min-device-pixel-ratio:0) and (min-color-index:0) { 
        @content;
    }
  }

// Icon styles for when adding icons for sudo classes
// -------------------------------------------------------------------------------------------------

@mixin icon-style {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// Color gradients
// -------------------------------------------------------------------------------------------------

@mixin liniar-gradient($top, $bottom){
    background: $top; /* Old browsers */
    background: -moz-linear-gradient(top,  $top 0%, $bottom 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$top), color-stop(100%,$bottom)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  $top 0%,$bottom 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  $top 0%,$bottom 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  $top 0%,$bottom 100%); /* IE10+ */
    background: linear-gradient(to bottom,  $top 0%,$bottom 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}