/* -------------------------------------------------------------------------------------------------
 * SAP - Typography
 * ---------------------------------------------------------------------------------------------- */

// Import webfonts
// -------------------------------------------------------------------------------------------------
@import 'icons/sap-icons';
@import 'webfonts/bentonsans';
@import 'icons/material-icons';

// Body text
// -------------------------

body {
	@include font-bentonsans;
	font-size: $font-size-base;
}

p {
	color: $blackAlphaOpaque;
	@include font-bentonsans;
	font-size: $font-regular;
}

// Text selection
// -------------------------------------------------------------------------------------------------
::selection {
	background-color: $sap-gold;
	color: $white;
} // /::selection



// Headings
// -------------------------------------------------------------------------------------------------


h1,
.h1 {
	@include font-bentonsans-bold;
	font-size: $font-large;
	line-height: $lineHeightTighter;
	text-rendering: optimizelegibility; // Fix the character spacing for headings
}

h2,
.h2 {
	@include font-bentonsans;
	font-size: $font-larger;
	line-height: $lineHeightTighter;
	text-rendering: optimizelegibility; // Fix the character spacing for headings
}

h3,
.h3 {
	@include font-bentonsans;
	font-size: $font-medium;
	line-height: $lineHeight;
	text-rendering: optimizelegibility; // Fix the character spacing for headings
}

h4,
.h4 {
	@include font-bentonsans-medium;
	font-size: $font-regular;
	line-height: $lineHeight;
	text-rendering: optimizelegibility; // Fix the character spacing for headings
}

h5,
.h5 {
	@include font-bentonsans-medium;
	font-size: $font-medium;
	line-height: $lineHeight;
	text-rendering: optimizelegibility; // Fix the character spacing for headings
}
h6,
.h6 {
	@include font-bentonsans-medium;
	font-size: $font-small;
	line-height: $lineHeight;
	text-rendering: optimizelegibility; // Fix the character spacing for headings
}


// Misc
// -------------------------

b,
strong {
	@include font-bentonsans-bold;
	font-weight: normal;
}


hr {
	background: $whiteDarker;
	border-top: $borderWidth solid $greyLightest;
	border: 0;
	display: block;
	height: $borderWidth;
	margin-bottom: $spaceMacroSmaller;
	padding: 0;
}


// Links
// -------------------------

a {
	color: $sap-blue-medium;
	text-decoration: none;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
		color: $sap-blue-hover;
	}

	&:focus {
		outline: thin dotted;
	}

	&:hover,
	&:active {
		outline: 0;
	}

	&.danger {
		color: $sap-red-medium;
	}

	&.danger:hover {
		color: lighten($sap-red-medium, 10);
	}

	&.no-underline {
		&:hover {
			text-decoration:none;
		}
	}

	&.dark {
		i {
			color: $blackAlphaDark;
			font-size: $font-medium;
		}
	}

	&.dark:hover {
		color: opacify($blackAlphaOpaque, 1);
	}
}

a:hover .icon {
	text-decoration: none;
}

// Custom classes
// -------------------------

.success-text {
	color: $sap-green-medium;
}

.warning-text {
	color: $warningText;
}

.muted {
	color: $greyLight;
}

h1 {
	.small {
		display: block;
	}
}
