// Social icons

.social-icon {
  height: 40px;
  width: 40px;
  display: block;

  &.twitter {
    background-image: url("#{$image-path}/share/twitter.png");
  }
  &.facebook {
    background-image: url("#{$image-path}/share/facebook.png");
  }
  &.linkedin {
    background-image: url("#{$image-path}/share/linkedin.png");
  }
  &.email {
    background-image: url("#{$image-path}/share/email.png");
  }
}