/* -----------------------------------------------------------------------------
 * Icons - generated by Icomoon
 * -----------------------------------------------------------------------------
 * Update whenever the icon set is regenerated
 * -------------------------------------------------------------------------- */

@font-face {
	font-family: 'icomoon';
	src:    url('#{$icon-path}/icomoon.eot?485x5f');
	src:    url('#{$icon-path}/icomoon.eot?485x5f#iefix') format('embedded-opentype'),
		url('#{$icon-path}/icomoon.ttf?485x5f') format('truetype'),
		url('#{$icon-path}/icomoon.woff?485x5f') format('woff'),
		url('#{$icon-path}/icomoon.svg?485x5f#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-alert:before {
  content: "\e940";
}
.icon-minus:before {
  content: "\e93f";
}
.icon-file-copy:before {
  content: "\e93e";
}
.icon-chat:before {
  content: "\e93c";
}
.icon-chat-outline:before {
  content: "\e93d";
}
.icon-shopping-cart:before {
  content: "\e8cc";
}
.icon-user:before {
  content: "\e7fd";
}
.icon-user-plus:before {
  content: "\e7fe";
}
.icon-add:before {
  content: "\e145";
}
.icon-check:before {
  content: "\e5ca";
}
.icon-check-circle:before {
  content: "\e86c";
}
.icon-edit:before {
  content: "\e254";
}
.icon-delete:before {
  content: "\e872";
}
.icon-heart-full:before {
  content: "\e87d";
}
.icon-heart:before {
  content: "\e87e";
}
.icon-keyboard_arrow_down:before {
  content: "\e313";
}
.icon-keyboard_arrow_left:before {
  content: "\e314";
}
.icon-keyboard_arrow_right:before {
  content: "\e315";
}
.icon-keyboard_arrow_up:before {
  content: "\e316";
}
.icon-print:before {
  content: "\e8ad";
}
.icon-city:before {
  content: "\e7f1";
}
.icon-warning:before {
  content: "\e002";
}
.icon-settings:before {
  content: "\e8b8";
}
.icon-unfold:before {
  content: "\e5d7";
}
.icon-cross:before {
  content: "\e900";
}
.icon-pdf:before {
  content: "\e93a";
  color: #9b9b9b;
}
.icon-faq:before {
  content: "\e93b";
  color: #9b9b9b;
}
.icon-content:before {
  content: "\e939";
}
.icon-training:before {
  content: "\e909";
}
.icon-profile:before {
  content: "\e91c";
}
.icon-dashboard:before {
  content: "\e924";
}
.icon-setting:before {
  content: "\e935";
}
.icon-bookmarks:before {
  content: "\e936";
}
.icon-connections:before {
  content: "\e937";
}
.icon-reputation:before {
  content: "\e938";
}
.icon-user-times:before {
  content: "\e925";
}
.icon-group:before {
  content: "\e926";
}
.icon-user-minus:before {
  content: "\e934";
}
.icon-download:before {
  content: "\e929";
}
.icon-target:before {
  content: "\e912";
}
.icon-chevron-thin-up:before {
  content: "\e92f";
}
.icon-chevron-thin-right:before {
  content: "\e930";
}
.icon-chevron-thin-left:before {
  content: "\e931";
}
.icon-chevron-thin-down:before {
  content: "\e932";
}
.icon-chevron-right:before {
  content: "\e91f";
}
.icon-chevron-left:before {
  content: "\e920";
}
.icon-chevron-down:before {
  content: "\e921";
}
.icon-chevron-up:before {
  content: "\e922";
}
.icon-calendar:before {
  content: "\e913";
}
.icon-arrow-up-right:before {
  content: "\e933";
}
.icon-up-right:before {
  content: "\e933";
}
.icon-arrow-top-right:before {
  content: "\e933";
}
.icon-play:before {
  content: "\e92e";
}
.icon-spinner2:before {
  content: "\e92d";
}
.icon-loading:before {
  content: "\e92d";
}
.icon-loading-wheel:before {
  content: "\e92d";
}
.icon-refresh:before {
  content: "\e92d";
}
.icon-repeat:before {
  content: "\e92d";
}
.icon-busy:before {
  content: "\e92d";
}
.icon-wait:before {
  content: "\e92d";
}
.icon-arrow:before {
  content: "\e92d";
}
.icon-loop:before {
  content: "\e92c";
}
.icon-clock:before {
  content: "\e92a";
}
.icon-clock2:before {
  content: "\e92b";
}
.icon-download2:before {
  content: "\e928";
}
.icon-spinner:before {
  content: "\e927";
}
.icon-bubble:before {
  content: "\e914";
}
.icon-pencil:before {
  content: "\e915";
}
.icon-earth:before {
  content: "\e916";
}
.icon-calendar2:before {
  content: "\e917";
}
.icon-checkmark:before {
  content: "\e918";
}
.icon-location:before {
  content: "\e919";
}
.icon-home:before {
  content: "\e91a";
}
.icon-file-pdf:before {
  content: "\e91b";
}
.icon-search:before {
  content: "\e91e";
}
.icon-user-secret:before {
  content: "\e91d";
}
.icon-info:before {
  content: "\e923";
}
.icon-linkedin:before {
  content: "\e901";
}
.icon-twitter:before {
  content: "\e902";
}
.icon-facebook:before {
  content: "\e903";
}
.icon-youtube:before {
  content: "\e904";
}
.icon-caret-right:before {
  content: "\e905";
}
.icon-caret-left:before {
  content: "\e906";
}
.icon-caret-up:before {
  content: "\e907";
}
.icon-caret-down:before {
  content: "\e908";
}
.icon-star-half-empty:before {
  content: "\e90a";
}
.icon-star-o:before {
  content: "\e90b";
}
.icon-star-half:before {
  content: "\e90c";
}
.icon-star:before {
  content: "\e90d";
}
.icon-chevron-right-fat:before {
  content: "\e90e";
}
.icon-chevron-left-fat:before {
  content: "\e90f";
}
.icon-chevron-down-fat:before {
  content: "\e910";
}
.icon-chevron-up-fat:before {
  content: "\e911";
}

// Chevron vertical alignment fix
.icon-chevron-right {
	position: relative;
	bottom: -.1em;
}
// Taget icon is slightly smaller so add class to compensate
.icon-lg {
    font-size: 1.35em;
	margin-left: -.14em;
	margin-right: .14em;
}// /.icon-lg

/* Star ratings
-------------------------------------------------- */

.star-rating {
	span {
		display: none;
	}

	&:before {
		font-family: 'icomoon' !important;
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		display: inline-block;
		text-decoration: inherit;
	}

	&.rating0-5 {

		&:before {
			  content: "\e90c \00a0 \e90b \00a0 \e90b \00a0 \e90b \00a0 \e90b \00a0";
		}
	}

	&.rating1-0 {

		&:before {
			  content: "\e90d \00a0 \e90b \00a0 \e90b \00a0 \e90b \00a0 \e90b \00a0";
		}
	}

	&.rating1-5 {

		&:before {
			  content: "\e90d \00a0 \e90c \00a0 \e90b \00a0 \e90b \00a0 \e90b \00a0";
		}
	}

	&.rating2-0 {

		&:before {
			  content: "\e90d \00a0 \e90d \00a0 \e90b \00a0 \e90b \00a0 \e90b \00a0";
		}
	}

	&.rating2-5 {

		&:before {
			  content: "\e90d \00a0 \e90d \00a0 \e90c \00a0 \e90b \00a0 \e90b \00a0";
		}
	}

	&.rating3-0 {

		&:before {
			  content: "\e90d \00a0 \e90d \00a0 \e90d \00a0 \e90b \00a0 \e90b \00a0";
		}
	}

	&.rating3-5 {

		&:before {
			  content: "\e90d \00a0 \e90d \00a0 \e90d \00a0 \e90c \00a0 \e90b \00a0";
		}
	}

	&.rating4-0 {

		&:before {
			  content: "\e90d \00a0 \e90d \00a0 \e90d \00a0 \e90d \00a0 \e90b \00a0";
		}
	}

	&.rating4-5 {

		&:before {
			  content: "\e90d \00a0 \e90d \00a0 \e90d \00a0 \e90d \00a0 \e90c \00a0";
		}
	}

	&.rating5-0 {

		&:before {
			  content: "\e90d \00a0 \e90d \00a0 \e90d \00a0 \e90d \00a0 \e90d \00a0";
		}
	}
}