/* -------------------------------------------------------------------------------------------------
 * SAP - Functions
 * ---------------------------------------------------------------------------------------------- */

// Get standard bootstrap rem spacer
@function get-spacer($level: 1, $axis: y) {
    @return map-get(map-get($spacers, $level), $axis);
} // /@function get-spacer

// Get a font weight
@function get-font-weight($weight) {
    @return map-get($font-weights, $weight);
} // /@function get-font-weight




// PX to REM converter, based on $font-size-root. Supply a px value
@function rem($px) {
    @return ($px / $font-base) * 1rem;
} // /@function rem()

/**
 * Usage: @include font-size($text-h4);
 * See _variables.scss for a list of available font-sizes to use.
 */
 @mixin font-size($fs-map, $fs-breakpoints: $grid-breakpoints) {
    @each $fs-breakpoint, $fs-font-size in $fs-map {
      @if $fs-breakpoint == null {
        @include make-font-size($fs-font-size);
      }
      @else {
        // If $fs-font-size is a key that exists in
        // $fs-breakpoints, use the value
        @if map-has-key($fs-breakpoints, $fs-breakpoint) {
          $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
        }
        @media screen and (max-width: $fs-breakpoint) {
          @include make-font-size($fs-font-size);
        }
      }
    }
  }

  // Utility function for mixin font-size
  @mixin make-font-size($fs-font-size) {
    // If $fs-font-size is a list, include
    // both font-size and line-height
    @if type-of($fs-font-size) == "list" {
      font-size: rem(nth($fs-font-size, 1));
      @if (length($fs-font-size) > 1) {
        line-height: nth($fs-font-size, 2);
      }
    }
    @else {
      font-size: rem($fs-font-size);
    }
  } 