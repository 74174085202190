/* -------------------------------------------------------------------------------------------------
 * SAP - Utility classes
 * ---------------------------------------------------------------------------------------------- */

// Text colours
// -------------------------------------------------------------------------------------------------
.text-default     		{ color: $blackAlphaOpaque 		!important; }
.text-black       		{ color: $black        	   		!important; }
.text-white       		{ color: $white        	   		!important; }
.text-red         		{ color: $sap-red-medium   		!important; }
.text-blue-bright  		{ color: $sap-blue-bright  		!important; }
.text-blue        		{ color: $sap-blue-medium  		!important; }
.text-green       		{ color: $sap-green-medium 		!important; }
.text-yellow      		{ color: $yellow       	   		!important; }
.text-grey          	{ color: $greyLight      		!important; }
.text-attendee-green	{ color: $sap-attendee-green	!important; }
.text-attendee-yellow	{ color: $sap-attendee-yellow	!important; }

.text-livechat {
	color: $greyLight;
	// md up (> 768px) ---------------------------------------------------------------------------------
	@include media-breakpoint-up(md) {
		color: $chat-blue;
	} // /@include media-breakpoint-up(md)
}


// Background colours
// -------------------------------------------------------------------------------------------------

.bg-black 				{ background-color: $black !important; }
.bg-white 				{ background-color: $white !important; }
.bg-white-opaque		{ background-color: $whiteAlphaOpaque !important; }
// .bg-white-darker 	{ background-color: $whiteDarker !important; }

.bg-snow-white 			{ background-color: $sap-snow-white !important; }
.bg-snow-grey 			{ background-color: $sap-snow-grey !important; }

.bg-grey 				{ background-color: $grey !important; }
.bg-grey-light			{ background-color: $greyLighter !important; }
.bg-grey-lightest		{ background-color: $greyLightest !important; }

.bg-gold 				{ background-color: $sap-gold !important; }

.bg-blue-bright 		{ background-color: $sap-blue-bright !important; }
.bg-blue-medium 		{ background-color: $sap-blue-medium !important; }
.bg-blue-deep 			{ background-color: $sap-blue-deep !important; }

.bg-purple-bright 		{ background-color: $sap-purple-bright !important; }
.bg-purple-medium 		{ background-color: $sap-purple-medium !important; }
.bg-purple-deep 		{ background-color: $sap-purple-deep !important; }

.bg-orange-bright 		{ background-color: $sap-orange-bright !important; }
.bg-orange-medium 		{ background-color: $sap-orange-medium !important; }
.bg-orange-deep 		{ background-color: $sap-orange-deep !important; }

.bg-green-bright 		{ background-color: $sap-green-bright !important; }
.bg-green-medium 		{ background-color: $sap-green-medium !important; }
.bg-green-deep 			{ background-color: $sap-green-deep !important; }



// Helper classes
// -------------------------------------------------------------------------------------------------

.hide {
  display: none;
}
.hide-select {
  display: none !important;
}
.show {
  display: block;
}
.removing {
	opacity: .5 !important;
}
.strong,
.bold {
	font-weight: bold;
}
.text-trunk {
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}// /.text-trunk

.alert-note {
	background: #f1f4f5;
}// /.alert-note

.clearfix {
	@include clearfix;
}// /.clearfix

.clearboth {
	clear: both;
}// /.clearboth

.full-width {
	margin-left: 0;
	margin-right: 0;
	width: 100%;
	li {
		&:before {
			display: none;
		}// /&:before
	}// /li
}// /.full-width

.line-height-1 {
	line-height: 1;
}
.auto-width {
	width: auto !important;
}// /.auto-width
.h-100 {
	height: 100%;
}
.no-underline {
	text-decoration: none;
	&:active,
	&:focus,
	&:hover {
		text-decoration: none;
	}
}// /.no-underline

.no-pointer {
	pointer-events: none;
}

// Alignment helper classes
// -------------------------------------------------------------------------------------------------

.m-a-05 { margin: 				0.5em !important; }// /.m-a-05
.m-t-05 { margin-top: 			0.5em !important; }// /.m-t-05
.m-r-05 { margin-right: 		0.5em !important; }// /.m-r-05
.m-b-05 { margin-bottom: 		0.5em !important; }// /.m-b-05
.m-l-05 { margin-left: 			0.5em !important; }// /.m-l-05
.m-x-05 { margin-left: 			0.5em !important; margin-right: 0.5em !important; }// /.m-x-05
.m-y-05 { margin-top: 			0.5em !important; margin-bottom: 0.5em !important; }// /.m-y-05

.p-a-05 { padding: 				0.5em !important; }// /.p-a-05
.p-t-05 { padding-top: 			0.5em !important; }// /.p-t-05
.p-r-05 { padding-right: 		0.5em !important; }// /.p-r-05
.p-b-05 { padding-bottom: 		0.5em !important; }// /.p-b-05
.p-l-05 { padding-left: 		0.5em !important; }// /.p-l-05
.p-x-05 { padding-left: 		0.5em !important; padding-right: 0.5em !important; }// /.p-x-05
.p-y-05 { padding-top: 			0.5em !important; padding-bottom: 0.5em !important; }// /.p-y-05

.m-y-negative { margin-top: -($spacer-y * 1.5); margin-bottom: -($spacer-y * 1.5); }
// Border helper classes
// -------------------------------------------------------------------------------------------------
.b-t-0 { border-top-width: 		0 !important; }
.b-r-0 { border-right-width: 	0 !important; }
.b-b-0 { border-bottom-width: 	0 !important; }
.b-l-0 { border-left-width: 	0 !important; }
.b-x-0 { border-right-width: 	0 !important; border-left-width: 0 !important; }
.b-y-0 { border-top-width: 		0 !important; border-bottom-width: 0 !important; }

.b-t-1 { border-top-width: 		$borderWidthThin !important; }
.b-r-1 { border-right-width: 	$borderWidthThin !important; }
.b-b-1 { border-bottom-width: 	$borderWidthThin !important; }
.b-l-1 { border-left-width: 	$borderWidthThin !important; }
.b-x-1 { border-right-width: 	$borderWidthThin !important; border-left-width: $borderWidthThin !important; }
.b-y-1 { border-top-width: 		$borderWidthThin !important; border-bottom-width: $borderWidthThin !important; }

.b-t-2 { border-top-width: 		$borderWidth !important; }
.b-r-2 { border-right-width: 	$borderWidth !important; }
.b-b-2 { border-bottom-width: 	$borderWidth !important; }
.b-l-2 { border-left-width: 	$borderWidth !important; }
.b-x-2 { border-right-width: 	$borderWidth !important; border-left-width: $borderWidth !important; }
.b-y-2 { border-top-width: 		$borderWidth !important; border-bottom-width: $borderWidth !important; }

.b-t-3 { border-top-width: 		$borderWidthThick !important; }
.b-r-3 { border-right-width: 	$borderWidthThick !important; }
.b-b-3 { border-bottom-width: 	$borderWidthThick !important; }
.b-l-3 { border-left-width: 	$borderWidthThick !important; }
.b-x-3 { border-right-width: 	$borderWidthThick !important; border-left-width: $borderWidthThick !important; }
.b-y-3 { border-top-width: 		$borderWidthThick !important; border-bottom-width: $borderWidthThick !important; }

.b-orange { border-color: $sap-gold; }
.b-grey-lighter { border-color: $greyLighter; }
.b-grey-lightest { border-color: $greyLightest; }
.b-alert-orange { border-color: $sap-orange-medium; }
.alert-orange { color: $sap-orange-medium; }

.b-t-solid { border-top-style: solid; }
.b-r-solid { border-right-style: solid; }
.b-b-solid { border-bottom-style: solid; }
.b-l-solid { border-left-style: solid; }

// position
.pos-rel {
	position: relative;
}


// Display helper classes
@each $bp in map-keys($grid-breakpoints) {
	@each $mode in map-keys($display-modes){
		.d-#{$bp}-#{$mode} {
			@include media-breakpoint-up($bp) {
				display: #{$mode} !important;
			}
		}
	}
}




// Font Size Helper classes
// -------------------------------------------------------------------------------------------------

.font-tiny { 					font-size: $font-tiny !important; 	} // 14px
.font-smaller { 				font-size: $font-smaller !important;} // 12px
.font-small { 					font-size: $font-small !important; 	} // 14px
.font-regular { 				font-size: $font-regular !important;} // 16px
.font-medium {		 			font-size: $font-medium !important; } // 18px
.font-large { 					font-size: $font-large !important; 	} // 22px
.font-larger { 					font-size: $font-larger !important;	} // 28px

// Alternative bullet point
.pdf-icon {
	&:before {
		content: "\e91b";
		@include icon-family;
		margin-right: .5em;
	}// /&:before
}// /.pdf-icon

// Alternative bullet point
.lh-icon-list {
	&__item {
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 1rem;
		&:before {
			color: $greyLight;
			font-size: 18px;
			@include icon-family;
			margin-left: -1.75em;
			top: 8px;
			position: relative;
		}// /&:before
	}

	&--pdf {
		li {
			&:before {
				color: $greyLight;
				font-size: 18px;
				content: "\e93a";
			}
		}
	}// /&--pdf
	&--faq {
		li {
			&:before {
				color: $greyLight;
				font-size: 18px;
				content: "\e93b";
			}
		}
	}// /&--faq
	&__btn {
		font-size: 16px;
	}
}// /.icon-list



// Button helper classes
// -------------------------------------------------------------------------------------------------

.btn-arrows {
	&:after {
		content: "\e908";
		display: inline-block;
		font-family: 'icomoon' !important;
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		margin-left: 1em;
		transform: rotate(0deg);
		transition: transform $anim-dur ease-in-out;
	}// /&:after
	&.collapsed {
		&:after {
			transform: rotate(-90deg);
		}// /&:after
	}// /&.collapsed
}

// BUTTONS
// ----------------------------------------------------------------------------------------------
.btn {
	background-color: $blackAlphaOpaque;
	border: 0;
	border-radius: 0;
	color: $white;
	white-space: normal;
	outline: none;
	transition: color $anim-dur ease-in-out, background-color $anim-dur ease-in-out;
	&:active,
	&:focus,
	&:active:focus {
		color: $white;
		outline: none;
	}
	&:hover {
		color: $white;
		background-color: $black;
	}// /&:hover

	&.btn-blue,
	&.btn-primary {
		background-color: $sap-blue-medium;
		&:hover {
			background-color: $sap-blue-hover;
		}// /&:hover
	}// /&.btn-blue

	&.btn-blue-border {
		background-color: $white;
		border: 1px solid $sap-blue-medium;
		color: $sap-blue-medium;
		&:hover {
			background-color: $sap-blue-hover;
			border-color: $sap-blue-hover;
			color: $white;
		}// /&:hover
	}// /&.btn-blue

	&.btn-grey {
		background-color: $greyLightest;
		color: $blackAlphaOpaque;
		&:active,
		&:focus,
		&:active:focus {
			color: $blackAlphaOpaque;
		}
		&:hover {
			background-color: $greyLight;
			color: $blackAlphaOpaque;
		}// /&:hover
	}// /&.btn-grey
	&.btn-grey-dark {
		background-color: $grey;
		color: $white;
		&:active,
		&:focus,
		&:active:focus {
			color: $white;
		}
		&:hover {
			background-color: lighten($grey, 10);
			color: $white;
		}// /&:hover
	}// /&.btn-grey-dark
	&.btn-text-link,
	&.btn-small,
	&.btn-mini {
		background-color: transparent;
		color: $sap-blue-medium;
		font-size: $font-tiny;
		padding: .375rem 0;
		&:active,
		&:focus,
		&:active:focus {
			color: $sap-blue-medium;
		}
		&:hover {
			color: $sap-blue-hover;
		}
		&.danger {
			color: $sap-red-medium;
		}
		&.btn-underline {
			text-decoration: underline;
		}
	}// /&.btn-text-link
	&.btn-responsive {
		width: 100%;
		// md up (> 768px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(md) {
			width: auto;
		} // /@include media-breakpoint-up(md)
	}// /&.btn-responsive
	&.btn-transparent-white {
		background-color: transparent;
		border: 1px solid $white;
		&:active,
		&:focus,
		&:active:focus {
			color: $white;
		}
		&:hover {
			background-color: $white;
			color: $greyDark;
		}// /&:hover
	} // /&.btn-white
	&.btn-white-blue {
		background-color: $white;
		border: 1px solid $white;
		color: $sap-blue-up;
		&:active,
		&:focus,
		&:active:focus {
			color: $sap-blue-up;
		}
		&:hover {
			background-color: $sap-blue-up;
			border: 1px solid $sap-blue-up;
			color: $white;
		}// /&:hover
	}
}// /.btn

.btn-play {
	display: inline-block;
	text-decoration: none;
	outline: none;
	position: relative;
	padding-left: 3em;

	&:before {
		@include icon-family;
		background-color: $sap-blue-medium;
		border-radius: 100%;
		color: $white;
		content: '\e92e';
		display: block;
		font-size: 1em;
	    padding: .1em .3em .1em .4em;
		position: absolute;
		left: 0em;
	}// /&:before

	&:hover,
	&:focus,
	.focus {
		color: lighten($sap-blue-medium, 10);
		outline: none;
		text-decoration: none;
		&:before {
			background-color: lighten($sap-blue-medium, 10);
		}// /&:before
	}// /&:hover,&:focus,.focus
}

button.close {
	opacity: .5;
	&:hover {
		opacity: 1;
	}
}

.unavailable-content {
	background-color: $sap-snow-grey;
	padding: rem(30px) rem(40px);
	p {
		color: $greyDarker;
	}
	// lg up (> 992px) ---------------------------------------------------------------------------------
	@include media-breakpoint-up(lg) {
		padding: rem(40px) rem(50px);
	} // /@include media-breakpoint-up(lg)
}

// Creds manager classes
// -------------------------------------------------------------------------------------------------

.up-heading {
	color: $greyDarker;
	border-bottom: 1px solid $greyLighter;
	font-size: rem(24px);
	line-height: 1;
	margin-bottom: rem(37px);
	padding-bottom: rem(14px);
	// lg up (> 992px) ---------------------------------------------------------------------------------
	@include media-breakpoint-up(lg) {
		padding-bottom: rem(19px);
	} // /@include media-breakpoint-up(lg)
}

.up-msg {
	padding: 5px 10px;
	&--warning {
		background-color: $sap-orange-warning;
		color: $sap-brown;
	}
}

// BS4 media query mixins, for reference/copy+paste
// -------------------------------------------------------------------------------------------------
// xs up (> 0px) -----------------------------------------------------------------------------------
@include media-breakpoint-up(xs) {
} // /@include media-breakpoint-up(xs)

// sm up (> 544px) ---------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {
} // /@include media-breakpoint-up(sm)

// md up (> 768px) ---------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
} // /@include media-breakpoint-up(md)

// lg up (> 992px) ---------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
} // /@include media-breakpoint-up(lg)

// xl up (> 1200px) --------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
} // /@include media-breakpoint-up(xl)

// Main content height helper
// -------------------------------------------------------------------------------------------------

$main-container-height: $header-height + $help-centre-footer-height + $footer-height;

.min-height-vp {
	// md up (> 768px) ---------------------------------------------------------------------------------
	@include media-breakpoint-up(md) {
		min-height: calc(100vh - #{$main-container-height});
	} // /@include media-breakpoint-up(md)
}


// Stylekit
// -------------------------------------------------------------------------------------------------
.h-50 {
	height: 50px;
}


// Main content container width override
// -------------------------------------------------------------------------------------------------

.container--lg {
	// xl up (> 1200px) --------------------------------------------------------------------------------
	@include media-breakpoint-up(xl) {
		max-width: 1450px;
	} // /@include media-breakpoint-up(xl)
}

// Next Gen - Student edition
.text-ng-yellow {
	color: $next-gen-yellow;
}
.text-ng-blue {
	color: $next-gen-blue;
}
.text-ng-underline {
	position: relative;
	&:before {
		background-color: $next-gen-yellow;
		content: '';
		height: 2px;
		width: 42px;
		bottom: -2px;
		left: 0;
		position: absolute;
	}
}
