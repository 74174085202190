/* -------------------------------------------------------------------------------------------------
 *
 * SAP - CP Page specific styles
 *
 * ---------------------------------------------------------------------------------------------- */
//header
.welcome-container{
    position: absolute;
    bottom: 3em;
    max-height: 270px;
    min-width: 370px;

    // md up (> 768px) ---------------------------------------------------------------------------------
    @include media-breakpoint-up(md) {
        bottom: auto;
        top: 50%;
        transform: translateY(-50%);
    } // /@include media-breakpoint-up(md)
}

.welcome-container h1 {
  font-size: 2rem;
}
 // Accordion
.img-icon {
    max-width: 100px;
    // md up (> 768px) ---------------------------------------------------------------------------------
    @include media-breakpoint-up(md) {
        max-width: 150px;
    } // /@include media-breakpoint-up(md)
}
 .accordion-outer {
    margin-top:10px;
    // md up (> 768px) ---------------------------------------------------------------------------------
    @include media-breakpoint-up(md) {
        width:100%;
        margin: 8em 0 0 0;
        position: relative;
        left:50%;
        transform: translateX(-50%);
        &.tab-count {
            &__1 {
                .accordion-tabs {
                    left: 40%;
                }
            }// /&__1
            &__2 {
                .accordion-tabs {
                    left: 30%;
                    &:nth-child(3){
                        border-right: 1px solid $greyLighter;
                        left: 50%;
                    }
                }
            }// /&__2
            &__3 {
                .accordion-tabs {
                    left: 20%;
                    &:nth-child(3){
                        left: 40%;
                    }
                    &:nth-child(5){
                        border-right: 1px solid $greyLighter;
                        left: 60%;
                    }
                }
            }// /&__3
            &__4 {
                .accordion-tabs {
                    left: 10%;
                    &:nth-child(3){
                        left: 30%;
                    }
                    &:nth-child(5){
                        left: 50%;
                    }
                    &:nth-child(7){
                        border-right: 1px solid $greyLighter;
                        left: 70%;
                    }
                }
            }// /&__4
            &__5 {
                .accordion-tabs {
                    left: 0%;
                    &:nth-child(3){
                        left: 20%;
                    }
                    &:nth-child(5){
                        left: 40%;
                    }
                    &:nth-child(7){
                        left: 60%;
                    }
                    &:nth-child(9){
                        border-right: 1px solid $greyLighter;
                        left: 80%;
                    }
                }
            }// /&__5
            &__6 {
                .accordion-tabs {
                    left: 0%;
                    &:nth-child(3){
                        left: 16.67%;
                    }
                    &:nth-child(5){
                        left: 33.33%;
                    }
                    &:nth-child(7){
                        left: 50%;
                    }
                    &:nth-child(9){
                        left: 66.67%;
                    }
                    &:nth-child(11){
                        border-right: 1px solid $greyLighter;
                        left: 83.33%;
                    }
                }
            }// /&__6
        }// /&.tab-count
    } // /@include media-breakpoint-up(md)

    .accordion-tabs {
        background-color: $white;
        border: 1px solid $greyLighter;
        border-bottom: none;
        color: $sap-blue-medium;
        cursor: pointer;
        position: relative;
        width: 100%;
        float:none;
        padding: 18px 0px;
        text-align: left;
        &.last-child {
            border: 1px solid $greyLighter;
        }
        p {
            color: inherit;
        }
        // md up (> 768px) ---------------------------------------------------------------------------------
        @include media-breakpoint-up(md) {
            border: 1px solid $greyLighter;
            border-right: 0;
            position: absolute;
            display: block !important;
            top: -4em;
            max-width: 20%;
        } // /@include media-breakpoint-up(md)
        &.active {
            background-color: $greyLight;
            color: $white;
        }//&.active
        &.maxTabs {
            @include media-breakpoint-up(md) {
                width: 16.6%;
            }
        }
        &.minTabs {
            @include media-breakpoint-up(md) {
                width: 20%;
            }
        }
    }//.accordionTabs
    .panel {
        top:0px;
        display: none;
        &.show {
            display: block;
        }
        &.panel-resource {
            .btn-play {
                margin-bottom: 1em;
            }
        }
    }//panel
}//accordian-outer

// Notification
.notification{
    display: none;
    background-color: transparentize($sap-gold, .1);
    position: fixed;
    top: 69px;
    left: 0;
    width: 100%;
    z-index: 20;
    &.show {
        display: block;
    }
    // md up (> 768px) ---------------------------------------------------------------------------------
    @include media-breakpoint-up(md) {
        position: absolute;
    } // /@include media-breakpoint-up(md)
    &__close {
        cursor: pointer;
        position: absolute;
        right: 1em;
        top: 0;
    }
}

// CMS area

.tile {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    height: 225px;
    width: 100%;
    position: relative;

    &__delete,
    &__edit,
    &__update,
    &__translate,
    &__clone {
        color: inherit;
    }
    
    &__modified {
        position: absolute;
        bottom: 1em;
        left: 0em;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

// Carousel image seletion
.image-list {
    border: 1px solid $greyLighter;
    overflow-x: scroll;
    &__container {
        float: left;
        margin: 0 10px 10px 0;
        position: relative;
        &.mw-200 {
            max-width: 200px;
            min-width: 100px;
        }
        &.mw-300 {
            max-width: 300px;
            min-width: 125px;
        }
        &.selected {
            .image-list__select {
                &:before {
                    transform: scale(1);
                }
            }
        }
    }
    &__select {
        background-color: $white;
        border: 1px solid $greyLighter;
        height: 40px;
        width: 40px;
        position: absolute;
        top: 0;
        right: 0;
        &:before {
            content: '\e5ca';
            display: block;
            font-family: 'icomoon' !important;
            speak: none;
            font-style: normal;
            font-size: $font-large;
            font-weight: bold;
            font-variant: normal;
            text-transform: none;
            line-height: 35px;
            text-align: center;
            position: relative;
            top: 0;
            left: 0;
            transform: scale(0);
            transition: transform ($anim-dur / 2) ease-in-out;
        }
    }
    &__delete {
        position: absolute;
        top: 2px;
        left: 4px;
    }
}

// Tab management
.column-tile {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    h3 {
        float: left;
        margin-bottom: 0;
        max-width: calc(100% - 55px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    &.bg-snow-grey{
        a {
            color: inherit;
            float: right;
            font-size: $font-20px;
            margin: 7px 0px 5px 10px;

            &:last-child {
                margin-right: 0;
                margin-left: 5px;
            }
        }
    }
}
.tab-content-container {
    border: 1px solid $greyLighter;
    // display: none;
    position: relative;
    &__close {
        cursor: pointer;
        position: absolute;
        top: 1em;
        right: 1em;
    }
}

.block-warning {
    font-size: 25px;
    position: absolute;
    bottom: -10px;
    right: 5%;
}

// Language customisation
.language-preview {
    padding-top: 2rem;
    // md up (> 768px) ---------------------------------------------------------------------------------
	@include media-breakpoint-up(md) {
		padding-top: 5rem;
    } // /@include media-breakpoint-up(md)
    .label-small {
        font-size: $font-smaller;
        width: 100%;
    }
    &__group {
        margin-bottom: 1rem;
        // md up (> 768px) ---------------------------------------------------------------------------------
        @include media-breakpoint-up(md) {
            &--title {
                min-height: 100px;
            }
            &--description {
                min-height: 290px;
            }
            &--column {
                min-height: 107px;
            }
            &--link {
                min-height: 600px;
            }
        } // /@include media-breakpoint-up(md)
    }
}
.edit-resource-header-translate {
    color: inherit;
}
