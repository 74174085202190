/* -------------------------------------------------------------------------------------------------
 * SAP - Page specific styles
 *
 * Try and avoid using this file for styles, opt instead for using global classes
 * to achieve consistent layout. This should only be used for very specific use cases to avoid
 * spaghetti code, and reduce CSS filesize
 *
 * ---------------------------------------------------------------------------------------------- */


// Homepage - 2017
// -------------------------------------------------------------------------------------------------
.homepage {
	.row-half-margin {
		margin-left: -$half-grid-gutter-width;
    	margin-right: -$half-grid-gutter-width;
	}
	.col-half-padding {
    	padding-left: $half-grid-gutter-width;
    	padding-right: $half-grid-gutter-width;
	}
}// /.homepage

.bg-img {
	background-size: cover;
	background-position: center;
	color: $black;
	height: 270px;
	&.slick-slide {
		height: 270px;
	} // REQUIRED TO OVERRIDE WEBCHAT SLICK SLIDER STYLES
	&--lg {
		height: 304px;
		// md up (> 768px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(md) {
			height: 494px;
		} // /@include media-breakpoint-up(md)
	}
}

// Hover toggle - 2017
.hover-toggle {
	ul {
		display: none;
		li {
			color: $greyDark;
			a {
				span {
					text-decoration: underline;
				}
			}
		}
	} // /ul
	&.active {
		&:before {
			transform: rotate(90deg);
		}
	}
}

// Homepage - 2020 Icon slider
// -------------------------------------------------------------------------------------------------
.icons-slider {
	background-color: $greyDarkest;
	color: $white;
	@include font-bentonsans;
	&__container {
		// md up (> 768px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(md) {
			display: flex;
		} // /@include media-breakpoint-up(md)
	}
	&__item {
		padding: 0 10px;
		text-align: center;
		// md up (> 768px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(md) {
			display: flex;
			flex: 1;
		} // /@include media-breakpoint-up(md)
		&-title {
			color: $white;
		}
		&-image {
			max-width: 90px;
			margin: auto;
		}
		&-link {
			@include font-bentonsans;
			color: $white;
			// md up (> 768px) ---------------------------------------------------------------------------------
			@include media-breakpoint-up(md) {
				width: 100%;
			} // /@include media-breakpoint-up(md)
		}
	}
	// Slick overrides
	.slick-slider {
		max-height: none;
	}
	.slick-arrow {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		background-color: transparent;
		border: 0;
		height: 94px;
		width: 30px;
		text-indent: -9999px;
		z-index: 1;
		background-image: url('#{$image-path}/icons/icon-arrow@2x.png');
		background-size: contain;
		background-repeat: no-repeat;
		&.slick-prev {
			left: 0px;
			transform: translateY(-50%) rotate(180deg);
		}
		&.slick-next {
			right: 0px;
		}
	}
}

// Homepage - 2020 onboarding
// -------------------------------------------------------------------------------------------------
.onboarding {
	.embed-container {
		margin-top: -1.25em;
		margin-left: -1.25em;
		margin-right: -1.25em;
	}
}

// Homepage - 2020 profile banner
// -------------------------------------------------------------------------------------------------

.profile-banner  {
	color: $white;
	background-color: $greyDarkest;
	&__image {
		height: auto;
		max-width: 90px;
		float: left;
		margin-right: 30px;
		margin-bottom: 40px;
	}
	&__title {
		font-size: 38px;
	}
	&__editions {
		float: right;
    width: calc(100% - 120px);
	}
	&__edition {
		color: $white;
		margin-bottom: 0;
		.profile-banner__editions--less-editions & {
			font-size: 16px;

		}
	}
	&__form {
		&-submit {
			position: absolute;
			top: 17px;
			left: 16px;
			cursor: pointer;
		}
		&-clear {
			display: none;
			position: absolute;
			top: 17px;
			right: 16px;
			cursor: pointer;
			.profile-banner__form-input--active & {
				display: inline-block;
			}
		}
		&-search {
			position: relative;
			i {
				color: $greyLightest;
				font-size: 25px;
			}
			&::before {
				background-color: $sap-gold;
				content: '';
				height: 4px;
				width: 54px;
				position: absolute;
				bottom: 0;
				left: 0;
			}
		}
		&-input {
			padding: 1rem 3.25rem;
		}
	}
}

// Homepage - 2020 Tiles
// -------------------------------------------------------------------------------------------------
.home-tiles {
	padding: 40px 0;
	&__heading {
		display: inline-block;
	}
	&__all-link {
		display: inline-block;
		margin-bottom: 1rem;
		// lg up (> 992px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(lg) {
			margin-left: 1rem;
			margin-bottom: 0;
		} // /@include media-breakpoint-up(lg)
	}
	&__container {
		@include clearfix;
		width: 100%;
	}
	&__tile {
		box-shadow: 0 0 12px $blackAlphaLight;
		display: block;
		float: left;
		font-size: $font-small;
		padding: 10px;
		margin-bottom: 30px;
		color: inherit;
		position: relative;
		width: 331px;
		height: 280px;
		max-width: 100%;
		&::after {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 30px;
			background: $white;
			background: linear-gradient(0deg, $white 0%, $white 50%, rgba(255,255,255,0) 100%);		
		}
		.home-tiles--dynamic & { 
			&::after {
				content: '';
			}
		}
		&:nth-last-child(-n+3):not(.home-tiles__tile--single) {
			display: none;
			.home-tiles--open & {
				display: block;
			}
		}
		&-middle {
			max-height: 180px;
			overflow: hidden;
			p {
				@include font-bentonsans-book;
			}
		}
		// md up (> 768px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(md) {
			margin-right: 32px;			
		} // /@include media-breakpoint-up(md)
		
		// lg up (> 992px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(lg) {
			margin-right: 32px;			
			&:nth-last-child(-n+3):not(.home-tiles__tile--single) {
				display: block;
			}
		} // /@include media-breakpoint-up(lg)
		
		&:last-child {
			margin-right: 0;
		}
		&-category {
			color: $greyLight;
			font-size: 12px;
			&--product {
				margin-top: 20px;
			}
		}
		&-title {
			@include font-bentonsans-medium;
			font-size: 14px;
			@include clearfix;
			height: 63px;
			.home-tiles--dynamic & {
				height: auto;
			}
		}
		&-image {
			margin: 0 -10px 15px;
			width: calc(100% + 20px);
			.home-tiles--dynamic & {
				width: 100%;
				max-width: 64px;
				margin: 0 15px 15px 0;
				float: left;
			}
		}
		&-product {
			@include font-bentonsans-medium;
		}
		// Variant
		
		&-course-details {
			padding: 0;
		}
		&-course-detail {
			display: inline-block;
			color: $greyLight;
			font-size: 12px;
			margin-bottom: 0;
			&::before {
				display: none;
			}
			&::after {
				content: "•";
				color: inherit;
				margin-right: 5px;
				margin-left: 5px;
			}
			&:last-of-type {
				&::after {
					display: none;
				}
			}
			&--overdue {
				color: $sap-red-medium;
			}
		}
	}
	&__toggle {
		width: 331px;
		max-width: 100%;
		// lg up (> 992px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(lg) {
			display: none;
		} // /@include media-breakpoint-up(lg)
		&-more {
			display: inline-block;
			.home-tiles--open & {
				display: none;
			}
		}
		&-less {
			display: none;
			.home-tiles--open & {
				display: inline-block;
			}
		}
	}

	&--empty {
		border: 1px solid $whiteDarker;
		color: $black;
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 280px;
		@include font-bentonsans-medium;
	}
}

// Nav tabs and result list
// ---------------------------------------------------------------------------------
.nav-tabs,
.result-types,
.nav-tabs-checkout {
	border-bottom: $borderWidthThin solid $greyLighter;
	@include clearfix;
	// margin-left: -$spaceMicroSmaller;

	li {
		display: block;
		float: left;
		font-size: $font-regular;
		line-height: $lineHeightTightest;
		margin-bottom: 1em;
		width: 100%;
		text-align: center;
		&:before {
			content: "";
		}
		a {
			color: $black;
			display: block;
			padding: $spaceMicroSmaller $spaceMicroSmall;
			text-decoration: none;
			h3{
				@include font-bentonsans;
				margin: 0;
			}
			span {
				color: $blackAlpha;
				@include font-bentonsans-book;
			}
			&:hover {
				text-decoration: underline;
			}
		}// /a
		&.active {
			a {
				background-color: $sap-gold;
				color: $white;
				position: relative;
				span {
					color: $whiteAlphaOpaque;
				}// /span
			}// /a

		}// /.active
	}// /li

	// sm up (> 544px) ---------------------------------------------------------------------------------
	@include media-breakpoint-up(sm) {
		li {
			&.active {
				a {
					border-radius: $borderRadius;
					&:after {
						border-color: $sap-gold transparent transparent transparent;
						border-style: solid;
						border-width: 7px 6.5px 0 6.5px;
						bottom: -7px;
						content: '';
						display: block;
						height: 0px;
						left: 50%;
						margin-left: -6.5px;
						position: absolute;
						width: 0px;
					}// /&:after
				}// /a
			}// /.active
		}// /li

	} // /@include media-breakpoint-up(sm)

	// lg up (> 992px) ---------------------------------------------------------------------------------
	@include media-breakpoint-up(lg) {
		li {
			width: auto;
		}
	} // /@include media-breakpoint-up(lg)

}// /.nav-tabs, .result-types


// Registration
// ---------------------------------------------------------------------------------

.c-inputs-stacked {
	label {
		width: 100%;
	}
}

// HELPCENTER
// ---------------------------------------------------------------------------------


.accordion-help {
	border-bottom: none 0;

	.accordion-group {
		border-top: none 0;
		border-bottom: $borderWidthThin solid $sap-snow-grey;

		&:last-child {
			border-bottom: none 0;
		}
	}

	.accordion-heading {
		color: $sap-blue-medium;
	}


	.accordion-body {
		padding: 0;
		&.collapse {
			display: none;
		}
	}// /.accordion-body


	.accordion-inner {
		background-color: $sap-snow-grey;
		padding: $spaceMicroSmall;
	}// /.accordion-inner

	.accordion-inner-list {
		list-style-type: none;
		margin: 0;
		padding: 0;
		li {
			margin-bottom: 1em;
			&:before {
				display: none;
			}
			&:last-child {
				margin: 0;
			}
			&:before {
				display: none;
			}
		}
		i {
			color: $sap-blue-medium;
		}
	}// /.accordion-inner-list
}// /.accordion-help


// Help Centre
// -------------------------------------------------------------------------------------------------

//Video slider
.video-slider {

	width: 100%;
	position: relative;
	//height: 300px;
	visibility: hidden;
	&.slick-initialized {
		visibility: visible;
	}// /&.slick-initialized
	.slick-list {
		overflow: hidden;
	}// /.slick-list
	.slick-slide {
		float: left;
		&.video-slide {
			cursor: pointer;
		}
		.inner-slide {
			margin: 10px;
			.caption {
				// font-size: $font-small;
				height: 3.3em;
				margin-bottom: 0;
				overflow: hidden;
			}// /.caption
		}// .inner-slide
	}// /.slick-slide
	.slick-arrow {
		background-color: transparent;
	    box-shadow: none;
	    border: none;
	    display: inline-block;
	    font-size: 0;
		position: absolute;
		z-index: 20;
		top: calc(50% + 25px);
		transform: translateY(-50%);
		outline: none;
		opacity: 1;
		transition: opacity $anim-dur ease-in-out;
		&.slick-disabled {
			opacity: 0.5;
		}

		&:before {
			//color: $text-default;
			display: block;
			font-size: 30px;
			font-family: 'icomoon' !important;
			speak: none;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			line-height: 1;
			text-indent: 0;
		}
		&.slick-next {
			right: -30px;

			&:before {
				content: "\e930";
			}// /&:before
		}// /&.slick-next

		&.slick-prev {
			left: -30px;

			&:before {
				content: "\e931";
			}// /&:before
		}// /&.slick-prev
	}// /.slick-arrow

	.slick-dots {
		display: none;
		position: absolute;
		bottom: 1em;
		margin-bottom: 0;
		padding: 0;
		width: 100%;
		text-align: center;
		li {
			display: inline-block;
				margin-right: 5px;

				&.slick-active {
						button {
								background-color: $white;
								transition: background-color .25s ease-in-out;

						} // /button
				} // /&.slick-active

				button {
					background-color: transparent;
						border: 3px solid $white;
						border-radius: 10px;
						font-size: 0;
						height: 20px;
						width: 20px;

						&:before {
								display: none;
						} // /&:before
				} // /button
		} // /li
    }
}// /.video-slider

// Video popup

#modalPopupBodyVideo {
	max-width: 100%;
	// sm up (> 544px) ---------------------------------------------------------------------------------
	@include media-breakpoint-up(sm) {
		max-width: calc(100% - 60px);
	} // /@include media-breakpoint-up(sm)
	// md up (> 768px) ---------------------------------------------------------------------------------
	// @include media-breakpoint-up(md) {
	// 	max-width: inherit;
	// } // /@include media-breakpoint-up(md)
	.close {
		color: $white;
		opacity: .5;
		position: absolute;
		right: 5px;
		z-index: 3;
		&:hover {
			opacity: 1;
		}
	}
}
.video-container {
	position: relative;
	z-index: 1;
	iframe {
		height: 300px !important;
		width: 100% !important;
		// md up (> 768px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(md) {
			height: 500px !important;
		} // /@include media-breakpoint-up(md)
	}// /iframe

	.video-js {
		&:hover .vjs-big-play-button {
			background-color: darken(rgb(0, 143, 211), 10%);
			border-bottom-color: rgb(0, 143, 211);
			-webkit-transition: all 0s;
			-moz-transition: all 0s;
			-o-transition: all 0s;
			transition: all 0s;
		}

		height: 300px;
		// md up (> 768px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(md) {
			height: 500px;
		} // /@include media-breakpoint-up(md)
		width: 100%;
		position: relative;
		video {
			height: 100%;
			width: 100%;
		}
		.vjs-big-play-button {
			background-color: rgb(0, 143, 211);
			border-bottom-color: rgb(0, 143, 211);
			border: none;
			border-radius: 150px;
			font-size: 30px;
			line-height: 23px;
			height: 94px;
			width: 94px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			// md up (> 768px) ---------------------------------------------------------------------------------
			@include media-breakpoint-up(md) {
				height: 114px;
				width: 114px;
			} // /@include media-breakpoint-up(md)

			&:before {
				color: $white;
				text-align: center;
				height: auto;
				font-size: 2em;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);


				// md up (> 768px) ---------------------------------------------------------------------------------
				@include media-breakpoint-up(md) {
					font-size: 3em;
				} // /@include media-breakpoint-up(md)
			}
		}// .vjs-big-play-button
	}// /.video-js
	.vjs-poster {
		background-size: cover;
	 	background-position-x: left;
	}// /.vjs-poster

}// /.video-container

// Topic & sub-topic page
// ----------------------------------------------------------------------------
.topic-list {
  padding: 0;
}
.topic-list li {
  font-size: 1rem;
  &:before {
     content: "";
   }
}
.topic-name {
  border: solid 1px $sap-snow-grey;
  padding: 5px;
  box-shadow: 0px 2px 4px $blackAlpha;
  background-color: $sap-snow-grey;
  word-wrap: break-word;
}
.add-topic {
  border: solid 1px $sap-snow-grey;
  padding: 5px;
  box-shadow: 0px 2px 4px $blackAlpha;
}
.new-topic-name {
   padding: 5px 0;
}
.topic-name-input {
  width: 315px;
  border: solid 1px $greyLighter;
}
.submit-topic {
  border: none;
  top: 2px;
  position: relative;
}
.topic-name-text:hover {
  cursor:pointer;
}
.topic-clicked {
  background-color: $greyLight;
  border: 1px solid $greyLight;
}
.sub-topic-name {
  border: solid 1px $sap-snow-grey;
  padding: 5px;
  box-shadow: 0px 2px 4px $blackAlpha;
  background-color: $sap-snow-grey;
}
.add-sub-topic {
  border: solid 1px $sap-snow-grey;
  padding: 5px;
  box-shadow: 0px 2px 4px $blackAlpha;
  background-color: $white;
}
.subtopic-name-input {
  width: 294px;
  border: solid 1px $greyLighter;
}
.submit-subtopic {
  background-color: #f4f4f4;
  border: none;
}
.inline-input-topic {
  width: 270px;
}
.inline-input-subtopic {
  width: 250px;
}

.list-no-style {
  padding: 0;
}
.list-no-style li {
  font-size: 1rem;
  border: solid 1px $sap-snow-grey;
  padding: 5px;
  box-shadow: 0px 2px 4px $blackAlpha;
  background-color: $sap-snow-grey;
  &:before {
     content: "";
   }
}
.topic-label {
  background-color: #f0ab00;
  color: white;
  margin-left: 30px;
  padding: 2px 5px;
}
.subtopic-label {
  background-color: #f0ab00;
  color: white;
  margin-left: 10px;
  padding: 2px 5px;
}

// Resource
// ----------------------------------------------------------------------------
.resource-name {
	display: inline-block;
}


// Editions
// ----------------------------------------------------------------------------
.edition-list,
.accordion-list {
  padding: 0;
}
.affected-ed-list {
	max-height: 90px;
	overflow-x: auto;
	overflow-y: scroll; 
	margin-top: 0.5rem;
}
.edition-list li, 
.accordion-list li {
	font-size: 1rem;
	position: relative;
	padding-left: 1.75rem;
  &:before {
    content: "";
	}
	&.new-edition-name,
	&.new-accordion-name {
		padding-left: 0;
	}
	&.edition-clicked {
		background-color: $greyLighter;
  		border: 1px solid $greyLighter;
		&:after {
			// md up (> 768px) ---------------------------------------------------------------------------------
			@include media-breakpoint-up(md) {
				content: "";
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 1rem 0 1rem 1rem;
				border-color: transparent transparent transparent $greyLighter;
				position: absolute;
				top: 50%;
				right: -1rem;
				transform: translateY(-50%);
				z-index: -1;
			}
		} // /@include media-breakpoint-up(md)
	}
}
.edition-name,
.accordion-name {
  border: solid 1px $sap-snow-grey;
  padding: 5px;
  box-shadow: 0px 2px 4px $blackAlpha;
  background-color: $sap-snow-grey;
	word-wrap: break-word;
	&-text {
		display: inline-block;
		width: calc(100% - 80px);
	}
}
.add-edition,
.add-accordion {
  border: solid 1px $sap-snow-grey;
  padding: 5px;
  box-shadow: 0px 2px 4px $blackAlpha;
}
.new-edition-name {
  padding: 5px 0;
}

.submit-edition, .cancel-add-edition {
  border: none;
  top: 2px;
  position: relative;
  padding: 0;
  width: 24px;
	border-radius: 5px;
	margin-right: 0.25rem;
}
.edition-name-text:hover {
  cursor:pointer;
}
.inline-input-edition {
  width: 270px;
}
.list-no-style {
  padding: 0;
}
.list-no-style li {
  font-size: 1rem;
  border: solid 1px $sap-snow-grey;
  padding: 5px;
  box-shadow: 0px 2px 4px $blackAlpha;
  background-color: $sap-snow-grey;
  &:before {
    content: "";
  }
}
.edition-label {
  background-color: #f0ab00;
  color: white;
  margin-left: 30px;
  padding: 2px 5px;
}

.edition-drag-icon,
.user-drag-icon,
.accordion-drag-icon {
	position: absolute;
	left: 5px;
	top: 50%;
	transform: translateY(-50%);
}

.delete-and-edit {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.add-another-edition {
	font-weight: 600;
}

#edit-edition-form p {
	margin-bottom: 0;
	font-weight: bold;
}

#edit-edition-form .row {
	margin-bottom: 8px !important;
	margin-top: 8px !important;
}

// Users
// ----------------------------------------------------------------------------
.user-list {
  padding: 0;
}
.user-list li {
	font-size: 1rem;
	position: relative;
	padding-left: 1.75rem;
	&:before {
    content: "";
	}
	&.new-user-name {
		padding-left: 0;
	}
	&.user-clicked {
		background-color: $greyLighter;
  	border: 1px solid $greyLighter;
		&:after {
			// md up (> 768px) ---------------------------------------------------------------------------------
			@include media-breakpoint-up(md) {
				content: "";
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 1rem 0 1rem 1rem;
				border-color: transparent transparent transparent $greyLighter;
				position: absolute;
				top: 50%;
				right: -1rem;
				transform: translateY(-50%);
				z-index: -1;
			}
		} // /@include media-breakpoint-up(md)
	}// /&.user-clicked
}// /.user-list li

.user-name {
  border: solid 1px $sap-snow-grey;
  padding: 5px;
  box-shadow: 0px 2px 4px $blackAlpha;
  background-color: $sap-snow-grey;
	word-wrap: break-word;
	&-text {
		display: inline-block;
		width: calc(100% - 40px);
		&:hover {
			cursor:pointer;
		}
	}
}
.add-user {
  border: solid 1px $sap-snow-grey;
  padding: 5px;
  box-shadow: 0px 2px 4px $blackAlpha;
}
.new-user-name {
  padding: 5px 0;
}

.user-permission {
	color: rgba(0,0,0,.8);
	font-size: .875rem;
	margin-bottom: 0.2rem;
}

.submit-user {
  border: none;
  top: 1px;
  position: relative;
}

.sub-user-block {
  border: 1px solid $greyLightest;
  padding: 10px;
  background-color: #f4f4f4;
  word-wrap: break-word;
}
.inline-input-user {
  width: 270px;
}
.user-label {
  background-color: #f0ab00;
  color: white;
  margin-left: 30px;
  padding: 2px 5px;
}

.edit-user-details dt, .edit-user-details dd {
	font-family: bentonsansregular,sans-serif;
	color: #373a3c;
}

.user-info-list {
	margin-bottom: 0.5rem;
}

.user-page-count {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

// SAP Learning Hub Help centre page
// ----------------------------------------------------------------------------
.blue-bar {
  width: 100%;
  background-color: $sap-blue-bright;
  color: white;
  min-height: 70px;
  padding: 15px 83px;
}

.topic-menu {
  padding: 10px 0;
  border-bottom: 1px solid $greyLighter;
}

.topic-menu-link {
  color: black;
  word-wrap: break-word;
}

.subtopic-menu-list {
  background-color: $sap-snow-grey;
}

.subtopic-menu-list li {
  padding: 10px;
  font-size: 1rem;
  margin-bottom: 0 !important;
  border-bottom: solid 1px $greyLighter;
}

.subtopic-menu-link {
  color: black;
  word-wrap: break-word;
}

.yellow-menu {
  background-color: $yellow;
  width: 265px;
  box-shadow: 0px 2px 4px $blackAlpha;

  .subtopic-menu-link {
    color: white;
  }
}

#topic-articles {
  padding: 10px;
}

.load-more-div {
  text-align: center;
}

// SAP Learning Hub front end footer
// ----------------------------------------------------------------------------
#help-centre-footer {
  background-color: $greyLighter;
  width: 100%;
  height: 70px;
  padding: 23px;
  text-align: center;
  font-weight: bold;
}

#help-centre-footer a {
  color: black;
}

.article-list-item {
  margin-bottom: 0.8rem;
}

.article-list-item a {
  font-weight: bold;
  font-size: 0.8rem;
}

// SAP CMS Landing
// ----------------------------------------------------------------------------

.column {
  float: left;
  width: 50%;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

// SAP Learning Hub learner profile page
// ----------------------------------------------------------------------------
.learner-list {
	margin-bottom: 0;
	margin-left: 0;
	font-size: 13px;
	line-height: 1.8;
}

.lh-accordion {
	border-top: 5px solid $greyLight;
	min-height: 69px;
	&--last {
		border-bottom: 5px solid $greyLight;
	}
	&__heading {
		font-size: 24px;
		position: relative;
		padding-right: 2rem;
	}
	
	.tab-chevron {
		position: absolute;
		right: 1rem;
		top: 50%;
		transform: translateY(-50%) rotate(0deg);
		transition: transform $anim-dur ease-in-out;
		bottom: initial;
		&:before {
			content: "\e921";
		}
		[aria-expanded="true"] & {
			transform: translateY(-50%) rotate(-90deg);
		}
	}

	.collapsed {
		 .tab-chevron:before {
			 content: "\e91f";
		 }
	}


	&__btn {
		color: $blackAlphaOpaque;
		&:active,
		&:focus,
		&:hover {
			color: $blackAlphaOpaque;
			text-decoration: none;
		}
	}

	p {
		font-size: 16px;
		font-family: bentonsanslight,sans-serif;

		&.header {
			font-family: bentonsansmedium, sans-serif;
		}
	}
	&__content {
		ul {
			li {
				font-size: $font-small;
				margin-bottom: .25em;
				&:before {
					padding-right: 1em;
					padding-left: 1em;
					font-size: $font-small;
				}
			}
		}
	}
}

.reputation-heading {
	font-size: 1.2rem;
}

.current-badge {
	max-width: 110px;
}

.recent-badge {
	max-width: 90px;
}

.v-center {
    display: inline-block;
    vertical-align: middle;
    float: none;
}

.subscription-list {
	margin-bottom: 0;
	font-size: 14px;
	line-height: 2.3;
	font-family: bentonsanslight,sans-serif;
}

.tab-chevron {
	font-size:30px;
}

.profile-h3 {
  font-size: 24px;
  display: inline-block;
}

.profile-light-h3 {
  font-family: bentonsanslight,sans-serif;
  font-size: 24px;
  display: inline-block;
}

.profile-content {
	font-size: 14px;
	font-family: bentonsanslight,sans-serif;

  p {
    .header {
      font-family: bentonsansmedium, sans-serif;
      font-weight: 700;
    }

    margin-bottom: 0.5rem;
  }
}

.profile-journey-align {
	margin-top: 2.3rem;
}
// SAP Learning Hub privacy notification page
// ----------------------------------------------------------------------------
.privacy-text h1 {
	max-width: 500px;
}

.privacy-text p {
	max-width: 900px;
	margin-bottom: 5px;
}

.privacy-tab-heading {
	font-size: 2.2rem;
}

// SAP Learning Hub login error page
// ----------------------------------------------------------------------------
.login-error {
	max-width: 60%;
	margin-left: auto;
	margin-right: auto;
	display: block;
}

// SAP Learning Hub cms alliance submissions
// ----------------------------------------------------------------------------
.download-link-wrapper {
	margin-right: 0.5rem;
}
	
.download-link {
	font-size: 18pt;
	display: inline-block;
}

// SAP Learning Hub portal alliance form
// ----------------------------------------------------------------------------
.alliance-content p {
	font-size: 14pt;
}

.alliance-content a {
	max-width: 200px;
	min-height: 55px;
	font-size: 12px;
}

.alliance-content a.normal-link {
	font-size: 18px;
}

#ua-ruler {
	display: inline-block;
	position: relative;
	height: 12px;
	width:100%;
	top: -18px;
	background-color: #f0ab00;
}

// SAP Learning Hub portal alliance form success
// ----------------------------------------------------------------------------
.ae-success-content {
	max-width: 650px;
	margin: 0 auto;
}

.ae-success-content h3 {
	font-size: 30pt;
}

// SAP Learning Hub portal consent-management
// ----------------------------------------------------------------------------
.consent-summary {
	font-size: 1rem;
}

// SAP Downtime notification
// ----------------------------------------------------------------------------
.maintenance-banner {
	display: none;
	&.show {
		display: block;
	}
	&__image {
		margin-bottom: 2em;
		margin-top: 1em;
		max-width: 150px;
		// md up (> 768px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(md) {
			margin-top: 2em;
			margin-bottom: 2em;
		} // /@include media-breakpoint-up(md)
	}
	&__close {
		cursor: pointer;
		position: absolute;
		right: 1.25rem;
		top: 0.5rem;
		font-size: 1.5rem;
    font-weight: 700;
		line-height: 1;
		opacity: .5;
		color: $black;
		// md up (> 768px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(md) {
			right: 1.75rem;
		} // /@include media-breakpoint-up(md)
		&:hover {
			opacity: 1;
		}
	}
	&__link {
		margin-right: 2rem;
		white-space: nowrap;
	}
}

// CMS downtime notification
.edition-select {
	border-radius: 0% !important;
	padding-right: 55px;
	&::before,
	&::after {
		display: block;
		position: absolute;
		right: 0;
		pointer-events: none;
		width: 40px;
		// md up (> 768px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(md) {
			width: 50px;
		} // /@include media-breakpoint-up(md)
	}
	&::before {
		content: '\e932';
		color: $white;
		font-family: 'icomoon' !important;
		speak: none;
		font-style: normal;
		font-size: $font-20px;
		font-weight: bold;
		font-variant: normal;
		text-transform: none;
		line-height: 35px;
		text-align: center;
		top: 6px;
		z-index: 2;
	}
	&::after {
		content: '';
		background-color: $sap-blue-medium;		
		height: 100%;
		top: 0px;
		z-index: 1;
	}
}

//custom select
// ----------------------------------------------------------------------------
.custom-select {
	position: relative;
	&::before,
	&::after {
		display: block;
		position: absolute;
		right: 0;
		pointer-events: none;
		width: 40px;
		// md up (> 768px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(md) {
			width: 50px;
		} // /@include media-breakpoint-up(md)
	}
	&::before {
		content: '\e932';
		color: $white;
		font-family: 'icomoon' !important;
		speak: none;
		font-style: normal;
		font-size: $font-20px;
		font-weight: bold;
		font-variant: normal;
		text-transform: none;
		line-height: 35px;
		text-align: center;
		top: 32px;
		z-index: 2;
		// md up (> 768px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(md) {
			top: 35px;
		} // /@include media-breakpoint-up(md)
	}
	&::after {
		content: '';
		background-color: $sap-blue-medium;		
		top: 30px;
		height: calc(100% - 30px);
		z-index: 1;
		// md up (> 768px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(md) {
			top: 32px;
			height: calc(100% - 32px);
		} // /@include media-breakpoint-up(md)
	}
}
.responsive-padding {
	padding-top: 2rem;
	// md up (> 768px) ---------------------------------------------------------------------------------
	@include media-breakpoint-up(md) {
		padding-top: 2.3rem;
	} // /@include media-breakpoint-up(md)
}
.responsive-label {
	font-size: $font-11px;
	// md up (> 768px) ---------------------------------------------------------------------------------
	@include media-breakpoint-up(md) {
		font-size: inherit;
	} // /@include media-breakpoint-up(md)
}

// CMS manage countries
// ----------------------------------------------------------------------------
.obj-name {
	border: solid 1px $sap-snow-grey;
  padding: 5px;
  box-shadow: 0px 2px 4px $blackAlpha;
  background-color: $sap-snow-grey;
	word-wrap: break-word;
	&-text {
		display: inline-block;
		width: calc(100% - 40px);
	}
}

.obj-list {
	padding: 0;
}

.obj-list li {
	font-size: 1rem;
	position: relative;
	padding-left: 1.75rem;
  &:before {
    content: "";
	}
	&.obj-clicked {
		background-color: $greyLighter;
  	border: 1px solid $greyLighter;
		&:after {
			// md up (> 768px) ---------------------------------------------------------------------------------
			@include media-breakpoint-up(md) {
				content: "";
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 1rem 0 1rem 1rem;
				border-color: transparent transparent transparent $greyLighter;
				position: absolute;
				top: 50%;
				right: -1rem;
				transform: translateY(-50%);
				z-index: -1;
			}
		} // /@include media-breakpoint-up(md)
	}
}

// SAP Learning Hub help centre
// ----------------------------------------------------------------------------

// Rich Text Format content
.lh-rtf {
	ul {
		padding-left: 4rem;
		margin: 2rem 0;
		li {
			font-size: 16px;
			line-height: 24px;
			margin-bottom: 1rem;
			position: relative;
			min-height: 24px;
			&:before {
				content: url('#{$image-path}/icons/check.svg');
				font-size: 18px;
				@include icon-family;
				margin-left: -3.5em;
				top: 50%;
				left: 0;
				position: absolute;
				transform: translateY(-50%);
			}
		}
	}// /ul
}// /.lh-rtf

.lh-contact {
	&__image {
		text-align: center;
		img {
			margin: auto;
			max-width: 128px;
		}
	}// /&__image
	&__details {
		h3 {
			text-transform: uppercase;
		}
	}// /&__details
	&__buttons {
		// md up (> 768px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(md) {
			.btn {
				&:first-child {
					margin-right: .25rem;
				}
				&:last-child {
					margin-left: .25rem;
				}
			}
		} // /@include media-breakpoint-up(md)
	}// /&__buttons
	&__region {
		font-size: 14px;
	}
	&__phone {
		font-size: $font-medium;
		@include font-bentonsans-medium; 
	}
	&__flag {
		border: 1px solid $white;
		border-radius: 100%;
		max-width: 35px;
		margin: 20px auto 14px;
	}// /&__flag
}

// Translatation language
.translate-form {
	&.disabled {
		opacity: .5;
		a {
			pointer-events: none;
		}
	
	}
}
.resource-name-input-translate {
	width: calc(100% - 60px);
}

.dropdown-item {
	&.current-language {
    background-color: $sap-snow-grey;
	}
}

// Footer content
.footer-translate-wrapper {
	&.disabled {
		opacity: 0.5;
		a {
			pointer-events: none;
		}
	}
}
.footer-content-container {
	border: 1px solid #ccc;
	position: relative;
	&__close {
		cursor: pointer;
    position: absolute;
    top: 1em;
    right: 1em;
	}
}
.footer-information-content,
.ext-link-content {
	display: inline-block;
	width: calc(100% - 50px);
}
.action-buttons {
	display: block;
	float: none;
	position: absolute;
	top: 50%;
	right: 1rem;
	transform: translateY(-50%);
}
.footer-social-network-icon {
	max-width: 35px;
	display: block;
	position: absolute;
	margin-right: 5px;
	top: 50%;
	transform: translateY(-50%);
	img {
		width: 35px;
		max-width: 100%;
	}
}
.footer-social-network-content {
	display: inline-block;
	margin-left: 45px;
	width: calc(100% - 85px);
}

// SAP Learning Hub Preferred Success
// ----------------------------------------------------------------------------
.ps-hero {
	background: transparent linear-gradient(270deg, #FFB600 0%, #D88200 100%) 0% 0% no-repeat padding-box;
	&__content {
		display: flex;
		justify-content: center;
		flex-direction: column;
		
		// md up (> 768px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(md) {
			min-height: calc(100vh - 144px);
			flex-direction: row;
		} // /@include media-breakpoint-up(md)
		
		&-left {
			flex: 1 0;
			// md up (> 768px) ---------------------------------------------------------------------------------
			@include media-breakpoint-up(md) {
				width: 70%;
			} // /@include media-breakpoint-up(md)
		}
		&-right {
			flex: 1 0;
			display: flex;
			align-items: flex-end;
			// md up (> 768px) ---------------------------------------------------------------------------------
			@include media-breakpoint-up(md) {
				width: 30%;
			} // /@include media-breakpoint-up(md)
		}
		&-image {
			max-width: 592px;
			margin-right: auto;
			margin-left: auto;
		}
		&-template {
			background-color: $white;
			padding: 32px 20px;
			max-width: 569px;
			min-height: 214px;
			position: relative;
			margin-bottom: 40px;
			a {
				@include font-bentonsans-book;
			}
		}
		&-subscribe {
			display: block;
			margin-top: 16px;
			// lg up (> 992px) ---------------------------------------------------------------------------------
			@include media-breakpoint-up(lg) {
				display: initial;
				margin-top: 0;
			} // /@include media-breakpoint-up(lg)
		}
	}
	&__heading {
		@include font-bentonsans-medium;
		max-width: 846px;
		margin-top: 54px;
		margin-bottom: 54px;
		// md up (> 768px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(md) {
			margin-top: 84px;
			margin-bottom: 84px;
			font-size: 60px;
			width: 200%;
		} // /@include media-breakpoint-up(md)
		// lg up (> 992px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(lg) {
			width: 100%;
		} // /@include media-breakpoint-up(lg)
	}

	&__sub-heading {
		// md up (> 768px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(md) {
			font-size: 27px;
		} // /@include media-breakpoint-up(md)
	}
	&__link {
		// md up (> 768px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(md) {
			margin-right: 5rem;
		} // /@include media-breakpoint-up(md)
		// lg up (> 992px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(lg) {
			margin-right: 10rem;
		} // /@include media-breakpoint-up(lg)
	}
}


.ps-content {
	// xl up (> 1200px) --------------------------------------------------------------------------------
	@include media-breakpoint-up(xl) {
		padding-right: 120px;
	} // /@include media-breakpoint-up(xl)
}

.ps-font-16 {
	font-size: $font-16px;
	@include font-bentonsans-book;
}

// Next Gen - Fonts
// ---------------------------------------------------------------------------------

.next-gen {
	@include font-bentonsans-book;
	@include font-size($text-body);

	h1, .h1 {
		@include font-bentonsans-medium;
		@include font-size($text-h1);
	}
	h2, .h2 {
		@include font-bentonsans;
		@include font-size($text-h2);
	}
	h3, .h3 {
		@include font-bentonsans;
		@include font-size($text-h3);
	}
	h4, .h4 {
		@include font-bentonsans;
		@include font-size($text-h4);
	}
	h5, .h5 {
		@include font-bentonsans;
		@include font-size($text-h5);
	}
	h6, .h6 {
		@include font-bentonsans;
		@include font-size($text-h6);
	}
	p {
		@include font-bentonsans-book;
		@include font-size($text-body);
	}
	ul, li, label {
		@include font-size($text-body);
		@include font-bentonsans-book;
	}
	.body-text {
		@include font-size($text-body);
		@include font-bentonsans-book;
		&--lg {
			@include font-size($text-body-lg);
		}
		&--sm {
			@include font-size($text-body-sm);
		}
	}
}


// Next Gen - Career paths
// ---------------------------------------------------------------------------------
.career-paths {
	&__tab {
		display: flex;
		justify-content: space-between;
		max-width: 330px;
		margin: 0 auto 40px;
		border-bottom: 1px solid $greyLightest;
		&-item {
			color: $greyLight;
			cursor: pointer;
			margin-bottom: 10px;
			transition: color $anim-dur ease-in-out;
			&::after {
				background-color: transparent;
				display: block;
				content: '';
				height: 3px;
				width: 100%;
				position: relative;
				top: 11px;
				transition: background-color $anim-dur ease-in-out;
			}
			&--active {
				color: $black;
				&::after {
					background-color: $sap-gold;
				}
			}
			
		}
	}
	&__tab-content {
		display: none;
		box-shadow: 0 0 15px $box-shadow;
		opacity: 0;
		&--active {
			display: flex;
			flex-direction: column;
			-webkit-animation: fadeIn $anim-dur forwards;
			animation: fadeIn $anim-dur forwards;
			// md up (> 768px) ---------------------------------------------------------------------------------
			@include media-breakpoint-up(md) {
				flex-direction: row;
			} // /@include media-breakpoint-up(md)	
		}
		&-image {
			background-size: cover;
			background-position: center;
			min-height: 300px;
			position: relative;
			// md up (> 768px) ---------------------------------------------------------------------------------
			@include media-breakpoint-up(md) {
				min-height: 474px;
				&::after {
					content: '';
					background: transparent linear-gradient(90deg, #FFFFFF 0%, #FFFFFFB3 39%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
					width: 211px;
					height: 100%;
					position: absolute;
					left: 0;
					top: 0;
					display: block;
				}
			} // /@include media-breakpoint-up(md)
		}
		&-child {
			&:first-child {
				padding: 1rem;
			}
			width: 100%;
			// md up (> 768px) ---------------------------------------------------------------------------------
			@include media-breakpoint-up(md) {
				width: 50%;
				&:first-child {
					padding: 52px 32px;
				}
			} // /@include media-breakpoint-up(md)
		}
		&-content {
			ul {
				padding-left: 30px;
			}
			ul li {
				font-size: $font-regular;
				&::before {
					color: $sap-gold;
					font-size: $font-large;
					margin-left: -30px;
					line-height: 16px;
				}
			}
		}
	}
}


// Next Gen - Registration form
// ---------------------------------------------------------------------------------
.next-gen {
	&__form-group {
		margin-bottom: 20px;
		position: relative;
	}
	&__form-errors {
		margin-top: -15px;
		margin-bottom: 20px;
		padding-left: 32px;
		position: relative;
		i {
			border: 1px solid;
			border-radius: 100%;
			padding: 2px;
			left: 0px;
			top: 0;
			position: absolute;
		}
		.success {
			color: $next-gen-green;
		}
		.error {
			color: $next-gen-red;
		}
		.warning {
			color: $next-gen-yellow;
		}
	}
	&__form-label {
		background-color: $white;
		color: $black;
		position: absolute;
		top: -8px;
		left: 5px;
		font-size: $font-regular;
		padding: 0 3px;
		position: absolute;
		pointer-events: none;
		user-select: none;
		transform: translate(0, 16px);
		transition: all $anim-fast ease-in-out;
		max-width: calc(100% - 6px);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	&__form-input {
		background-color: $white;
		border: 1px solid $next-gen-border;
		line-height: 1;
		padding: 12px 10px;
		width: 100%;
		&--password {
			padding-right: 50px;
		}
		&:active,
		&:focus {
			border-color: $next-gen-blue;
		}
		&::placeholder {
			color: transparent;
		}
		
		&:focus,
		&:not(:placeholder-shown) {
			& + .next-gen__form-label {
				color: $next-gen-border;
				font-size: $font-smaller;
				padding-right: 3px;
				transform: translate(0, 0);
				.next-gen__form-input--error & {
					color: $next-gen-red;
				}
			}
			&:focus {
				& + .next-gen__form-label {
					color: $next-gen-blue;
					.next-gen__form-input--error & {
						color: $next-gen-red;
					}
				}
			}
		}
		
		// IE11 specific
		&:not(:-ms-input-placeholder) {
			& + .next-gen__form-label {
				color: $next-gen-border;
				font-size: $font-smaller;
				padding-right: 3px;
				transform: translate(0, 0);
			}
		}
		
		&--error {
			border-color: $next-gen-red;
			&:focus,
			&:not(:placeholder-shown) {
				& + .next-gen__form-label {
					color: $next-gen-red;
				}
			}
		}
	}
	&__password-toggle {
		color: $next-gen-blue;
		cursor: pointer;
		position: absolute;
		right: 8px;
		top: 8px;
	}
	&__form-checkbox {
		&:not(:checked),
		&:checked {
			position: absolute;
			left: -9999px;
		}
		&:not(:checked) + label,
		&:checked + label {
			position: relative;
			padding-left: 1.95em;
			cursor: pointer;
		}

		&:not(:checked) + label:before,
		&:checked + label:before {
			// background: $white;
			border: solid 1px $next-gen-border;
			border-radius: 0;
			content: '';
			height: 22px;
			left: 0;
			position: absolute;
			top: 0rem;
			width: 22px;
			transition: all $anim-dur ease-in-out;
		}
		&:not(:checked) + label:before {
			background-color: $white;
		}
		&:checked + label:before {
			background-color: $next-gen-blue;
			border: solid 1px $next-gen-blue;
		}
		&:not(:checked) + label:after {
			opacity: 0;
		}
		/* checked mark aspect */
		&:not(:checked) + label:after,
		&:checked + label:after {
			color: $white;
			content: '\e5ca';
			@include icon-style;
			font-size: 1.2rem;
			font-weight: bold;
			line-height: 0.8;
			position: absolute;
			top: 2px;
			left: 1px;
			transition: transform $anim-dur ease-in-out;
			transform: scale(0);
		}
		&:checked + label:after {
			opacity: 1;
			transform: scale(1);
		}
	}
	&__btn-r-margin {
		margin-right: 20px;
	}
}

.next-gen-register {
	&__container {
		background-color: $white;
		box-shadow: 0 0 15px $box-shadow;
		padding: 32px 20px;
		max-width: 394px;
		margin-top: -50px;
		position: relative;
		z-index: 1;
		// md up (> 768px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(md) {
			margin-top: -120px;
			margin-bottom: -50%;
		} // /@include media-breakpoint-up(md)
		
		// xl up (> 1200px) --------------------------------------------------------------------------------
		@include media-breakpoint-up(xl) {
			margin-top: -192px;
		} // /@include media-breakpoint-up(xl)
		&--lg {
			max-width: 569px;
		}
	}
	&__title {
		margin-bottom: 20px;
	}
	&__input-group {
		display: flex;
		flex-direction: column;
		// sm up (> 544px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(sm) {
			flex-direction: row;
		} // /@include media-breakpoint-up(sm)
	}
	&__flex-child {
		flex: 1;
		
		&:last-child {
			margin-top: 20px;
			// sm up (> 544px) ---------------------------------------------------------------------------------
			@include media-breakpoint-up(sm) {
				margin-left: 20px;
				margin-top: 0;
				max-width: 159px;
			} // /@include media-breakpoint-up(sm)
		}
	}
}

// Next Gen - Stats
// ---------------------------------------------------------------------------------
.next-gen-stats {
	&__columns {
		max-width: 1000px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin: auto;
		// md up (> 768px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(md) {
			flex-direction: row;
		} // /@include media-breakpoint-up(md)
	}
	&__column {
		// md up (> 768px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(md) {
			max-width: 200px;
		} // /@include media-breakpoint-up(md)
		// xl up (> 1200px) --------------------------------------------------------------------------------
		@include media-breakpoint-up(xl) {
			max-width: 260px;
		} // /@include media-breakpoint-up(xl)
	}
	&__footnote {
		display: block;
		font-size: $font-tiny;
		// md up (> 768px) ---------------------------------------------------------------------------------
		@include media-breakpoint-up(md) {
			max-width: 359px;
		} // /@include media-breakpoint-up(md)
	}
}

// Next Gen - Separator
// ---------------------------------------------------------------------------------
.next-gen-separator {
	background-color: $greyLightest;
	margin-bottom: 1em;
}

// Next Gen - Modal
// ---------------------------------------------------------------------------------
.next-gen {
	.modal-dialog {
		max-width: 465px;
		// transform: translateY(-25%);
		margin-top: 50vh;
		transform: translateY(-50%);
	}
	.modal.fade {
		transform: translateY(-25%);
	}
	.modal.in {
		.modal-dialog {
			
			transform: translateY(-50%);
		}
	}
	.modal-body {
		text-align: center;
	}
	.modal-content {
		.modal-header {
			padding-top: 20px;
			padding-bottom: 10px;
		}
	}
	&__modal-paragraph {
		max-width: 230px;
		margin: auto auto 40px;
	}
}