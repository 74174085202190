/* -----------------------------------------------------------------------------
 * Animations - Keyframes, SVG animations, etc.
 * -------------------------------------------------------------------------- */

 @import 'animations/fade-in';

 .animated {
	-webkit-animation-duration: $anim-dur;
	animation-duration: $anim-dur;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}// /.animated